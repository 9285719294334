// =============================================
// container for whole tabs and lists with items

.trading-token-tabs-container {
  width: 100%;
  margin-top: 20px;
}

// =============================================
// container for list with items on each tab

.items-area {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 24px;
  padding-bottom: 64px;

  .items-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 8px;

    width: 100%;
    height: fit-content;
    border-radius: 12px;
  }
}

// =============================================
// Holders

.trading-token-holder-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 8px;

  width: 100%;
  padding: 8px 0;
  padding-bottom: 16px;
  border-bottom: 1px rgba(255, 255, 255, 0.3) solid;

  font-family: PPPangramSansRounded-Semibold;
  font-size: 14px;

  .holder-left {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
    gap: 8px;

    .image {
      width: 36px;
      height: 36px;
      img {
        width: 36px;
        height: 36px;
        border-radius: 90%;
      }
    }

    .info {
      display: flex;
      flex-direction: column;
      align-items: start;
      justify-content: start;
      gap: 4px;

      font-family: PPPangramSansRounded-Semibold;
      font-size: 14px;

      .top {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: start;
        gap: 4px;

        .creator {
          text-align: left;
          color: #3694ff;
        }

        .name {
          text-align: left;
        }
      }

      .offchainTokens {
        text-align: left;
        color: #777;
      }
    }
  }

  .holder-right {
    display: flex;
    flex-direction: column;
    align-items: end;
    justify-content: center;
    gap: 4px;

    .value {
      font-family: PPPangramSansRounded-Semibold;
      font-size: 14px;
    }
  }
}

// =============================================
// Transactions

.trading-token-transaction-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 8px;

  width: 100%;
  padding: 8px 0;
  padding-bottom: 16px;
  border-bottom: 1px rgba(255, 255, 255, 0.3) solid;

  font-family: PPPangramSansRounded-Semibold;
  font-size: 14px;

  .transaction-left {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
    gap: 10px;

    .image {
      width: 36px;
      height: 36px;

      img {
        width: 36px;
        height: 36px;
        border-radius: 90px;
      }
    }

    .info {
      // background-color: pink;
      display: flex;
      flex-direction: column;
      align-items: start;
      justify-content: start;
      gap: 4px;

      font-family: PPPangramSansRounded-Semibold;
      font-size: 14px;

      .name {
        // color: yellow;
        text-align: left;
      }
      .bottom {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: start;
        gap: 4px;

        .type {
          font-size: 12px;
          &.buy {
            color: #09d0a8;
          }
          &.sell {
            color: #d00934;
          }
        }

        .icon {
          width: 15px;
          height: 15px;
          margin-top: -1px;
          img {
            width: 15px;
            height: 15px;
          }
        }

        .value {
          font-family: PPPangramSansRounded-Semibold;
          font-size: 12px;
          color: white;

          &.gold {
            color: #f5c005;
          }
        }
      }
    }
  }

  .transaction-right {
    display: flex;
    flex-direction: column;
    align-items: end;
    justify-content: center;
    gap: 4px;

    .offchainTokens {
      font-family: PPPangramSansRounded-Semibold;
      font-size: 14px;
    }
    .time {
      font-family: PPPangramSansRounded-Semibold;
      font-size: 12px;
      color: #777;
    }
  }
}

// =============================================
// price-area

.trading-price-area-container {
  transition: padding-bottom 0.35s ease-in-out;

  .trading-price-area {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 8px;

    width: 100%;
    padding: 8px 0;
    // padding-top: 20px;
    padding-bottom: 16px;

    font-family: PPPangramSansRounded-Semibold;
    font-size: 14px;

    .price-area-left {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: start;
      gap: 24px;

      .image {
        width: 36px;
        height: 36px;
        img {
          width: 36px;
          height: 36px;
        }
      }

      .current {
        display: flex;
        flex-direction: column;
        align-items: start;
        justify-content: space-between;
        gap: 4px;

        height: 40px;

        font-family: PPPangramSansRounded-Semibold;
        font-size: 14px;

        .label {
          font-family: PPPangramSansRounded-Medium;
          color: #777;
          text-align: left;
        }

        .price {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: start;
          gap: 4px;

          .icon {
            width: 15px;
            height: 15px;
            margin-top: -1px;
            img {
              width: 15px;
              height: 15px;
            }
          }

          .value {
            font-family: PPPangramSansRounded-Semibold;
            font-size: 20px;
            color: white;
          }

          .percent {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: start;
            gap: 4px;

            font-family: PPPangramSansRounded-Semibold;
            font-size: 14px;
            color: white;

            &.green {
              color: #09d0a8;
            }
            .red {
              color: #d00934;
            }

            &.gold {
              color: #f5c005;
            }
          }
        }
      }
    }

    .price-area-right {
      .image {
        width: 48px;
        height: 48px;
        img {
          width: 48px;
          height: 48px;
        }
      }
    }
  }
}

// =============================================
// section content for each trading tab

.trading-section {
  font-family: PPPangramSansRounded-Medium;
  font-size: 14px;
  line-height: 22px;
  color: #777;

  // margin-top: 4px;
  // padding-bottom: 8px;
  // border-bottom: 1px rgba(255, 255, 255, 0.3) solid;

  // &.overview {
  //   padding-bottom: 8px;
  // }

  // &.about {
  //   padding-bottom: 28px;
  // }

  .text {
    text-align: center;
    margin-bottom: 8px;
  }

  .two-line-ellipsis {
    overflow: hidden;
    width: 100%;
    display: -webkit-box;
    line-clamp: 2;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  // overview

  .item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    text-align: left;

    margin-bottom: 6px;

    // .label {}

    .value {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 8px;

      .value {
        font-family: PPPangramSansRounded-Semibold;
        color: white;

        &.gold {
          color: #f5c005;
        }
      }

      .icon {
        width: 20px;
        height: 20px;
        margin-top: -1px;
        img {
          width: 20px;
          height: 20px;
        }
      }
    }
  }

  // about

  .about-items {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 24px;

    .about-item {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 6px;

      font-family: PPPangramSansRounded-Semibold;
      font-size: 13px;
      color: white;

      background-color: #222;
      border-radius: 900px;
      padding: 4px 12px;
      padding-top: 5px;

      .icon {
        width: 16px;
        height: 16px;
        margin-top: -3px;
        img {
          width: 16px;
          height: 16px;
        }
      }

      // .label {}
    }
  }
}

@import './Modals.scss';

.popup {
  z-index: 9998;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  padding: 16px;

  // --blur-amount: 32px;
  // background-color: var(--navbar-color);
  // -webkit-backdrop-filter: blur(var(--blur-amount));
  // backdrop-filter: blur(var(--blur-amount));
  // background-color: rgba(0, 0, 0, 0.6);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .modal-button-close {
    height: 32px;
    top: 10px;
    right: 10px;
  }

  .pop-title {
    font-family: PPPangramSansRounded-SemiBold;
    font-size: 22px;
    // font-size: 20px;
    display: flex;
    flex: 0.5;
    align-items: center;
    justify-content: center;

    padding-bottom: 12px;
  }

  .pop-description {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;

    font-size: 15px;
    line-height: 20px;

    padding-bottom: 16px;
  }

  .pop-score {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;

    padding-top: 0;
    padding-bottom: 20px;

    .text {
      font-family: PPPangramSansRounded-SemiBold;
      font-size: 20px;
      padding-top: 2px;
      color: white;
    }

    .icon {
      height: 20px;
      margin-left: 4px;
      img {
        height: 100%;
      }
    }
  }

  .pop-footer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0.5;
    width: 100%;
    .pop-cta {
      height: 50px;
      width: 80%;
    }
  }

  .pop-content {
    // border: 1px solid white;
    border: 1px solid #444;
    width: 100%;
    padding: 24px; // 12px 8px;
    border-radius: 16px; // 8px;
    display: flex;
    flex-direction: column;
    position: relative;
    min-height: 200px;
    align-items: center;
    justify-content: space-around;
    background-color: rgba(0, 0, 0, 0.8);
  }
}

.trading-input-container {
  position: relative;
  opacity: 1;
  width: 100%;

  form {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 4px;

    .trading-input {
      border: unset;
      outline: none;
      &:focus {
        outline: none;
      }

      border-radius: 20px;
      font-family: PPPangramSansRounded-Medium;
      font-size: 20px;
      width: 100%;
      height: 40px;
      margin: 0;
      padding: 0 8px;

      box-sizing: border-box;

      background-color: transparent;
      color: #fff;
      box-sizing: border-box;
    }

    .trading-input-icon {
      width: 20px;
      height: 20px;
    }
  }
}

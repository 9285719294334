.drawer-layout.powerup-earn {
  .drawer-header.powerup-earn {
    .drawer-header-subtitle.powerup-earn {
      color: white;
      padding-top: 8px;
      padding-bottom: 24px; // 40px;

      .text.powerup-earn {
        font-size: 18px;
      }
    }

    .score {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;

      padding-top: 32px;
      padding-bottom: 24px;

      .text {
        font-family: PPPangramSansRounded-SemiBold;
        font-size: 32px;
        padding-top: 2px;
        color: white;
      }

      .icon {
        height: 30px;
        margin-left: 4px;
        img {
          height: 100%;
        }
      }
    }
  }
}

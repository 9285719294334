// ==========================================================================
// Basic Card

.card {
  // background-color: #600;
  cursor: pointer;
  width: 100%;
  height: 50px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 8px;

  .card-left {
    // background-color: #333;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
    gap: 12px;

    width: calc(100svw - 130px);

    .rank {
      // background-color: red;
      padding-top: 4px;
      img {
        width: 30px;
        height: 30px;
      }
    }

    .image {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 32px;
      height: 32px;
      img {
        background-color: yellow;
        border-radius: 100%;
        width: 32px;
        height: 32px;
      }
    }

    .info {
      // background-color: blue;
      position: relative;
      width: calc(100svw - 210px);

      display: flex;
      gap: 4px;
      flex-direction: column;
      align-items: start;
      justify-content: center;

      .name {
        font-size: 18px;
        font-family: PPPangramSansRounded-Medium;
        text-align: left;

        // truncate team member names with ellipsis
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 100%;
      }

      .users {
        font-size: 16px;
        font-weight: medium;
        color: #777;
      }
    }
  }

  .score {
    display: flex;
    align-items: center;

    .text {
      font-size: 16px;
    }

    .icon {
      height: 20px;
      margin-left: 4px;
      img {
        height: 100%;
      }
    }
  }
}

// ==========================================================================

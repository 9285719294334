.modal-drawer.generic {
  .drawer-layout {
    .drawer-header {
      // .drawer-header-icon {
      //   img {
      //     max-height: 190px;
      //   }
      // }

      .drawer-header-title {
        color: white;
        // margin-top: -32px;
        padding-top: 16px;
      }

      .drawer-header-subtitle {
        color: #ccc;
        // padding-top: 32px;
        // padding-bottom: 40px;
        padding-top: 16px;
        padding-bottom: 16px;
      }

      .drawer-header-subtitle-2 {
        color: white;
        padding-bottom: 16px;
        font-size: 20px;
        font-weight: bold;
      }

      .score {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;

        padding-top: 16px;
        padding-bottom: 16px;

        .text {
          font-family: PPPangramSansRounded-SemiBold;
          font-size: 32px;
          padding-top: 2px;
          color: white;
        }

        .icon {
          height: 30px;
          margin-left: 4px;
          img {
            height: 100%;
          }
        }
      }
    }
  }
}

// =============================================
// trading offchainToken item

.trading-token-item-profile-farming {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;

  padding: 16px 0;
  padding-bottom: 16px;
  border-bottom: 1px rgba(255, 255, 255, 0.3) solid;

  .trading-token-item-profile-farming-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    gap: 12px;
    width: 100%;
    height: 48px;

    .trading-token-item-profile-farming-image {
      width: 48px;
      height: 48px;
      border-radius: 12px;
      overflow: hidden;

      img {
        width: 100%;
      }
    }

    .trading-token-item-profile-farming-content {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      width: calc(100% - 48px);

      // ----------------------
      .trading-token-item-market-cap {
        display: flex;
        flex-direction: row;
        gap: 6px;
        align-items: center;
        justify-content: start;
        font-family: PPPangramSansRounded-Semibold;
        font-size: 12px;
        text-align: left;

        .trading-token-item-market-cap-label {
          color: #777;
          text-align: left;
          padding-top: 2px;
        }
        .trading-token-item-market-cap-value {
          display: flex;
          flex-direction: row;
          gap: 4px;
          align-items: center;

          .label {
            margin-top: 3px;
          }

          .icon {
            height: 14px;
            img {
              height: 100%;
            }
          }
        }

        .trading-token-item-market-cap-percent {
          font-size: 10px;
          color: #09d0a8;
          margin-top: 3px;
        }
      }
      // -----------------------------

      .left {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 4px;

        .name-container {
          display: flex;
          flex-direction: row;
          justify-content: start;
          gap: 4px;

          font-family: PPPangramSansRounded-SemiBold;
          font-size: 14px;

          .tickername {
            color: white;
          }
          .tokenname {
            color: #777;
          }
        }

        .owned-container {
          display: flex;
          flex-direction: row;
          justify-content: start;
          gap: 4px;

          font-family: PPPangramSansRounded-SemiBold;
          font-size: 14px;

          .label {
            color: #777;
          }
          .value {
            color: white;
          }
        }
      }

      .right {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 4px;

        .price-container {
          display: flex;
          flex-direction: row;
          gap: 6px;
          align-items: center;
          justify-content: end;

          .label {
            font-family: PPPangramSansRounded-SemiBold;
            font-size: 14px;
            margin-top: 3px;
          }

          .farming-coin {
            width: 16px;
            height: 16px;
            border-radius: 90px;
            overflow: hidden;

            margin-top: -2px;

            img {
              width: 100%;
            }
          }
        }

        .farming-container {
          display: flex;
          flex-direction: row;
          gap: 4px;
          align-items: start;
          justify-content: end;

          .farming-label {
            font-family: PPPangramSansRounded-SemiBold;
            font-size: 12px;
            color: white;
            width: fit-content;
            opacity: 0.5;
            padding-top: 1px;

            &.yellow {
              color: #f5c005; // yellow'
              opacity: 1;
            }
          }

          // .arrow {
          //   width: 10px;
          //   height: 8px;

          //   img {
          //     width: 100%;
          //   }

          //   &.green {
          //     margin-top: -3px;
          //   }
          //   &.red {
          //     margin-top: -2px;
          //   }
          // }

          // .percent {
          //   font-family: PPPangramSansRounded-Bold;
          //   font-size: 12px;
          //   color: white;
          //   &.green {
          //     color: #09d0a8;
          //   }
          //   &.red {
          //     color: #d00948;
          //   }
          // }
        }
      }
    }
  }
}

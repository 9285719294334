.drawer-details.gift-back {
  .gift-back-button {
    font-size: 16px;
    width: auto;
    padding-left: 60px;
    padding-right: 60px;
    margin: 0 auto;
    text-align: center;
  }

  .gift-back-header {
    padding: 0px;
  }

  .gift-back-title {
    font-family: PPPangramSansRounded-Bold;
    color: white;
    font-size: 26px;
    margin-top: 18px;
  }

  .gift-back-value {
    font-family: PPPangramSansRounded-Bold;
    color: white;
    font-size: 28px;
    height: 75px;
    align-content: center;
    margin-top: 5px;
  }

  .gift-back-sender {
    font-family: PPPangramSansRounded-Regular;
    color: white;
    font-size: 14px;
    opacity: 0.6;
    margin-top: 5px;
  }

  .drawer-header-icon {
    display: flex;
    align-items: center;
    justify-content: center;

    .img-header {
      width: 150px;
      height: 150px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      border: 3px solid white;
      position: relative;

      .img-container {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 92%;
        height: 92%;
        overflow: hidden;
        border-radius: 50%;

        img {
          width: 120%;
          height: 120%;
        }
      }

      .overlay-img-header {
        position: absolute;
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        border-radius: 50%;
        border: 4px solid black;
        padding: 6px;
        background-color: white;
        bottom: 0;
        right: 0;

        .overlay-img-container {
          overflow: hidden;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            width: 100% !important;
            height: 100% !important;
          }
        }
      }
    }
  }

  .drawer-gift-back-give-get {
    font-family: PPPangramSansRounded-Medium;
    font-size: 15px;
    margin-bottom: 20px;
  }
}

// =============================================
// tabs - solid rounded style

.tabs .btn {
  position: relative;
}

.tabs-header-rounded {
  width: 100%;
  height: 40px;

  background-color: #222;
  border-radius: 6px;

  &.extra-rounded {
    border-radius: 90px;
  }

  padding: 4px 0;
  box-sizing: border-box;

  .tabs {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    gap: 4px;

    width: 100%;
    height: 100%;

    padding: 0 4px;
    box-sizing: border-box;

    .tab {
      display: flex;
      justify-content: center;
      align-items: center;

      width: 100%;
      height: 100%;

      border-radius: 6px;
      padding: 4px;

      &.extra-rounded {
        border-radius: 90px;
      }

      font-family: PPPangramSansRounded-Semibold;
      font-size: 12px;

      &.selected {
        background-color: black;
      }
    }
  }
}

// =============================================
// tabs - underline style

.tabs-header-underline {
  width: 100%;
  height: 40px;

  padding: 4px 0;

  .tabs {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    gap: 4px;

    width: 100%;
    height: 100%;

    .tab {
      display: flex;
      justify-content: center;
      align-items: center;

      width: 100%;
      height: 100%;

      border-radius: unset;
      padding: 8px; // 16px;

      font-family: PPPangramSansRounded-Semibold;
      font-size: 12px;

      &.selected {
        border-bottom: 1px solid #fff;
      }
    }
  }
}

.tabs-header-large {
  width: 100%;
  height: 40px;

  .tabs {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 24px;

    width: 100%;
    height: 100%;

    margin-top: 10px;
    margin-bottom: 25px;

    padding: 0;
    box-sizing: border-box;

    .tab {
      display: flex;
      justify-content: center;
      align-items: center;

      height: 100%;

      border-radius: 0;
      padding: 0;

      font-family: PPPangramSansRounded-Bold;
      font-size: 18px;

      &.selected {
        background-color: unset;
        color: white;
      }

      &:not(.selected) {
        color: #777;
      }
    }
  }
}

// =============================================
// trading offchainToken

.trading-banner {
  position: relative;
  width: 100%;
  height: 77px;

  border: 1px yellow solid;
  border-radius: 10px;
  box-sizing: border-box;

  margin-top: 8px;

  .trading-banner-new {
    display: flex;
    align-items: center;
    justify-content: center;

    position: absolute;
    width: 44px;
    height: 20px;
    left: calc(50% - 22px);
    top: -10px;

    background: linear-gradient(305.83deg, #ffee10 27.33%, #ffb900 86.11%);
    border-radius: 900px;

    font-family: PPPangramSansRounded-Bold;
    font-size: 11px;
    color: black;
  }

  .trading-banner-bg {
    z-index: -1;
    position: absolute;
    right: -2px;

    width: 190px; // 163px;
    height: 76px; // 75px;
    background-image: url('../../../../assets/mockups/trading/trading_banner_bg.png');
    background-size: contain;
    background-repeat: no-repeat;

    border-radius: 10px;

    opacity: 0.7; // added some opacity so text over it becomes more readable
  }

  .trading-banner-content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
    gap: 16px;

    padding: 16px;
    margin-top: 3px;

    .trading-banner-cup {
      background-image: url('../../../../assets/mockups/trading/trading_banner_cup.png');
      background-size: contain;
      background-repeat: no-repeat;
      width: 29px;
      height: 36px;
    }

    .trading-banner-info {
      display: flex;
      flex-direction: column;
      align-items: start;
      justify-content: center;
      gap: 0;

      // text-shadow: 2px 2px black;

      .trading-banner-info-top {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: start;
        gap: 8px;

        font-family: PPPangramSansRounded-Bold;
        font-size: 14px;
        text-align: left;

        // .title {}
        .info-button {
          img {
            width: 20px;
            height: 20px;
          }
        }
      }
      .trading-banner-info-bottom {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: start;
        gap: 8px;

        font-family: PPPangramSansRounded-Semibold;
        font-size: 14px;
        text-align: left;
        color: #aaa;

        //  .owned {}
        //  .separator {
        //  .earn-hr-label {}

        .earn-hr-value {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: start;
          gap: 4px;

          font-family: PPPangramSansRounded-Bold;
          color: #f5c005;

          .icon {
            margin-top: -2px;
            height: 14px;
            img {
              height: 100%;
            }
          }
        }
      }
    }
  }
}

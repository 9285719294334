.page.trading-token {
  background-color: black;
}

.trading-token-layout {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 4px;

  width: 100%;

  .trading-token-header {
    display: flex;
    flex-direction: row;
    align-items: start;
    justify-content: start;
    gap: 16px;

    padding-top: 8px;

    // .trading-token-header-bg {
    //   margin-left: -24px;
    //   width: calc(100% + 48px);
    //   height: 230px;
    //   background-image: url('../../../assets/mockups/trading/trading_details_header_bg.png');
    //   background-size: contain;
    //   background-repeat: no-repeat;
    //   background-position: center;
    // }

    // image

    .trading-token-header-image {
      width: 100px;
      height: 100px;
      border-radius: 12px;
      overflow: hidden;

      img {
        width: 100%;
      }
    }

    .trading-token-header-info {
      display: flex;
      flex-direction: column;
      align-items: start;
      justify-content: center;
      gap: 7px;

      width: calc(100% - 100px);
      margin-top: 8px;

      // title

      .trading-token-header-title {
        font-family: PPPangramSansRounded-SemiBold;
        font-size: 24px;
        text-align: left;
      }

      // author

      .trading-token-author {
        display: flex;
        flex-direction: row;
        gap: 6px;
        align-items: center;
        justify-content: center;
        margin-top: -2px;

        .trading-token-author-by {
          font-family: PPPangramSansRounded-Medium;
          font-size: 12px;
          color: #999;
          text-align: left;
        }

        .trading-token-author-image {
          width: 20px;
          height: 20px;
          border-radius: 100%;
          img {
            width: 20px;
            height: 20px;
            border-radius: 100%;
          }
        }
        .trading-token-author-name {
          font-family: PPPangramSansRounded-Semibold;
          font-size: 12px;
          text-align: left;
        }
      }

      // buttons -> edit / owned / share

      .trading-token-buttons {
        display: flex;
        flex-direction: row;
        gap: 6px;
        align-items: center;
        justify-content: center;

        .trading-token-button-edit {
          background-color: black;
          border: 0.5px solid white;
          border-radius: 900px;
          padding: 8px 12px;
          height: 30px;

          font-family: PPPangramSansRounded-Semibold;
          font-size: 13px;
        }

        .trading-token-button-owned {
          background-color: #222;
          border-radius: 900px;
          padding: 8px 12px;
          height: 30px;

          font-family: PPPangramSansRounded-Semibold;
          font-size: 13px;
        }

        .trading-token-button-share {
          background-color: #222;
          border-radius: 900px;
          padding: 8px 8px;
          height: 32px;
          img {
            width: 16px;
            height: 16px;
          }
        }
      }
    }
  }
}

.drawer-layout.powerup {
  .drawer-header {
    padding-bottom: 16px;
    // ===============================
    // image

    .drawer-header-icon {
      img {
        height: 22svh !important;
        width: 22svh !important;
        max-height: 190px;
        max-width: 190px;
      }
    }

    // ===============================
    // title and subtitle

    .drawer-header-subtitle.powerup {
      color: #999;
      padding-top: 16px;
      padding-bottom: 8px;
      line-height: 22px; // 26px;
      font-size: 14px;
    }

    // ===============================
    // earn

    .puc-earn {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      padding-top: 8px; // 16px;
      padding-bottom: 8px; // 16px;

      .puc-earn-title {
        margin-right: 8px;

        font-family: PPPangramSansRounded-Medium;
        font-size: 18px;
        color: #999;
      }

      .puc-earn-coins {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        font-family: PPPangramSansRounded-Bold;
        font-size: 18px;
        color: #f5c005;

        img {
          margin-right: 6px;
          width: 15px;
        }
      }
    }

    .puc-price {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      padding-top: 8px;
      padding-bottom: 8px;

      // &.base {}
      // &.fee {}

      .puc-earn-title {
        margin-right: 8px;

        font-family: PPPangramSansRounded-Medium;
        font-size: 14px;
        color: #999;
      }

      .puc-earn-coins {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        font-family: PPPangramSansRounded-Bold;
        font-size: 14px;
        color: #f5c005;

        img {
          margin-right: 6px;
          width: 12px;
        }
      }
    }

    // ===============================
    // score

    .score {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;

      padding-top: 4px;
      padding-bottom: 1svh; // 16px;

      .text {
        font-family: PPPangramSansRounded-SemiBold;
        font-size: 32px;
        padding-top: 2px;
        color: white;
      }

      .icon {
        height: 30px;
        margin-left: 4px;
        img {
          height: 100%;
        }
      }
    }

    .drawer-header-icon {
      &.worker {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        position: relative;

        div {
          font-size: 50px;
          margin: 0 4px;
        }
      }

      .picture {
        position: absolute;
        border-radius: 50%;
        bottom: -10px;
        right: 50px;
        width: 80px;
      }
    }
  }
}
.multiple-gifts-container {
  max-height: 75svh;
  padding: 0 10px;

  .slider-card {
    margin: 0 30px;
    border: 2px solid #646464;
    border-radius: 6px;
    padding: 20px 10px 5px;

    &.single-card {
      margin-bottom: 30px;
    }
  }

  .slick-list {
    max-height: 58svh;
  }

  .slick-track {
    height: 55svh;
  }

  .slick-slider {
    margin-bottom: 5svh;

    .slick-dots li {
      transition: all 0.5s ease;

      width: 12px;
      height: 12px;
      border-radius: 20px;
      background-color: white;
      opacity: 0.5;

      &.slick-active {
        opacity: 1;
        width: 48px;
      }
    }

    .slick-dots li button:before {
      color: white;
    }

    .slick-dots li.slick-active button:before {
      color: white;
    }

    .slick-arrow {
      width: 50px;
      height: 100%;
      z-index: 20;

      img {
        position: absolute;
        top: 50%;
        margin-top: -30px;
        left: 13px;
      }
      &:before {
        display: none;
      }
    }
  }

  .drawer-header-icon img {
    width: 130px;
    height: 130px;
    margin: 0 auto;
  }

  .drawer-layout.powerup .drawer-header {
    padding-bottom: 0;

    .drawer-header-title {
      font-size: 4svh;
      padding-top: 2svh;
    }

    .drawer-header-subtitle {
      padding-top: 1.5svh;
      padding-bottom: 1svh;
      line-height: 1.8svh;
      font-size: 1.4svh;
      max-height: 12svh;
      overflow: hidden;
      -webkit-line-clamp: 6;
      -webkit-box-orient: vertical;
      display: -webkit-box;
    }
    .puc-earn {
      .puc-earn-title {
        font-size: 3svh;
        &.golden {
          color: #f5c005;
        }
      }

      .puc-earn-coins img {
        width: 3svh;
      }
    }
  }
}

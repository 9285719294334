.drawer-layout.friend {
  .drawer-header {
    .drawer-header-subtitle {
      color: #ccc;
      padding-top: 32px;
      padding-bottom: 40px;

      // .text {
      //   font-family: PPPangramSansRounded-Medium;
      //   font-size: 18px;
      //   color: #666;
      //   padding: 16px 12px;
      //   line-height: 28px;

      //   color: #ccc !important;
      //   padding-top: 32px !important;
      //   padding-bottom: 40px !important;
      // }
    }

    .score {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;

      padding-top: 16px;
      padding-bottom: 16px;

      .text {
        font-family: PPPangramSansRounded-SemiBold;
        font-size: 32px;
        padding-top: 2px;
        color: white;
      }

      .icon {
        height: 30px;
        margin-left: 4px;
        img {
          height: 100%;
        }
      }
    }
  }
}

.buttons-invite {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 8px;

  width: 100%;

  .btn-invite {
    // unneeded since flex + min-width will take care of auto-adjusting
    // width: calc(100% - 64px);
  }

  .btn-copy {
    width: 64px;
    min-width: 64px;

    img {
      width: 28px;
      height: 28px;
    }

    .copy-success {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 8px;

      border-radius: 20px;
      background-color: black;
      border: 1px solid #222;

      position: absolute;
      top: -44px;
      left: -32px; // otherwise disappears under page overflow horizontal padding...

      padding: 6px;
      padding-right: 10px;
      box-sizing: border-box;

      .copy-success-icon {
        width: 20px;
        height: 20px;
        img {
          width: 20px;
          height: 20px;
        }
      }

      .copy-success-text {
        font-family: PPPangramSansRounded-Medium;
        font-size: 13px;
        color: white;
        padding-top: 2px;
        box-sizing: border-box;
      }
    }
  }
}

// =============================================
// trading offchainToken item

.trading-token-item {
  width: 100%;
  justify-content: start !important;

  padding: 16px 0;
  padding-bottom: 16px;
  border-bottom: 1px rgba(255, 255, 255, 0.3) solid;

  .trading-token-item-container {
    display: flex;
    flex-direction: row;
    gap: 16px;
    height: 80px;

    .trading-token-item-image {
      width: 80px;
      height: 80px;
      border-radius: 12px;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        display: block;
      }
    }

    .trading-token-item-content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 4px;

      .trading-token-item-top {
        .trading-token-item-title {
          font-family: PPPangramSansRounded-Bold;
          font-size: 14px;
          text-align: left;
          margin-top: 4px;
          margin-bottom: 4px;
        }

        .trading-token-item-market-cap {
          display: flex;
          flex-direction: row;
          gap: 6px;
          align-items: center;
          justify-content: start;
          font-family: PPPangramSansRounded-Semibold;
          font-size: 12px;
          text-align: left;

          .trading-token-item-market-cap-label {
            color: #777;
            text-align: left;
            padding-top: 2px;
          }
          .trading-token-item-market-cap-value {
            display: flex;
            flex-direction: row;
            gap: 4px;
            align-items: center;

            .label {
              margin-top: 3px;
            }

            .icon {
              height: 14px;
              img {
                height: 100%;
              }
            }
          }

          .trading-token-item-market-cap-percent {
            font-size: 10px;
            color: #09d0a8;
            margin-top: 3px;
          }
        }
      }

      .trading-token-item-bottom {
        .trading-token-item-author {
          display: flex;
          flex-direction: row;
          gap: 4px;
          align-items: center;
          margin-bottom: 4px;

          .trading-token-item-author-tappable {
            display: flex;
            flex-direction: row;
            gap: 4px;
            align-items: center;
          }

          .trading-token-item-author-label {
            font-family: PPPangramSansRounded-Medium;
            font-size: 12px;
            color: #999;
            text-align: left;
          }

          .trading-token-item-author-image {
            width: 20px;
            height: 20px;
            border-radius: 100%;
            img {
              width: 20px;
              height: 20px;
              border-radius: 100%;
            }
          }
          .trading-token-item-author-name {
            font-family: PPPangramSansRounded-Semibold;
            font-size: 12px;
            text-align: left;
          }
        }
      }
    }
  }
}

.cms {
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .img-container {
    margin: 12px;
    margin-bottom: 24px;
    display: flex;
    flex-direction: column;

    .btn-clear {
      margin-top: 8px;
    }
  }

  .img-box {
    width: 200px;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid white;
    border-radius: 8px;

    img {
      width: 100%;
      border-radius: 8px;
    }

    .btn-add {
      font-size: 100px;
    }
  }

  .error {
    margin-top: 24px;
    p {
      color: red;
    }
  }

  .result {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    a {
      margin-bottom: 8px;
    }
  }

  .link {
    margin-bottom: 24px;
    button {
      margin-top: 12px;
    }
  }
}

// =============================================
// daily combo

.daily-combo-header {
  background-color: #222;
  border-radius: 12px;
  width: 100%;
  height: 64px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  padding: 8px 12px;

  .left {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
    gap: 8px;

    font-family: PPPangramSansRounded-Semibold;
    font-size: 16px;
    text-align: left;

    .title-label {
      text-align: left;
    }

    .title-timer {
      color: #999;
      text-align: left;
      padding-top: 6px;
    }

    img {
      padding-top: 5px;
    }
  }

  .right {
    display: flex;
    flex-direction: column;
    align-items: end;
    justify-content: center;
    gap: 2px;

    font-family: PPPangramSansRounded-Semibold;
    font-size: 18px;
    color: #f5c005;

    .top {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 5px;

      img {
        width: 18px;
        height: 18px;

        &.daily-info {
          width: 20px;
          height: 20px;
        }
      }
    }

    .bottom {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 6px;

      .right-reward-label {
        padding-top: 3px;
      }

      img {
        width: 20px;
        height: 20px;
      }
    }
  }
}

// =============================================
// header slots

.daily-combo-slots {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 8px;

  font-family: PPPangramSansRounded-Semibold;
  font-size: 12px;

  .daily-combo-slot {
    position: relative;
    width: 27.5svw;
    height: 97%; // 100%;
    border: 1px solid #333;
    border-radius: 12px;
    overflow: hidden;

    .slot-content {
      position: relative;
      img {
        width: 100%;
        height: 100%;
      }
    }

    &.active {
      margin-top: 1px;

      .slot-content {
        width: 100%;
        height: 100%;

        img {
          width: 100%;
          height: 100%;
        }

        .label {
          position: absolute;
          bottom: 10%;
          width: 100%;
          background-color: black;
          padding: 4px 8px;
          padding-top: 5px;
        }
      }
    }
  }
}

// =============================================
// container for whole tabs and lists with items

.mine-tabs-container {
  width: 100%;
  // overflow-x: hidden;
}

// =============================================
// container for list with items on each tab

.items-area {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 16px;

  .items-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 8px;

    width: 100%;
    height: fit-content;
    border-radius: 12px;
  }
}

// =============================================

.modal-header.trading {
  position: relative;
  width: 100%;

  .trading-header-bg {
    z-index: -1;

    margin-top: -16px;
    margin-left: -24px;

    height: 296px;
    width: calc(100% + 48px);

    background-image: url('../../../../assets/mockups/trading/trading_header_bg.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }

  .trading-header-row-container {
    &.title {
      margin-top: -160px;
      margin-bottom: 12px;
    }

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
  }

  .trading-header-title {
    font-family: PPPangramSansRounded-Semibold;
    font-size: 28px;
    text-align: left;
  }

  .trading-header-button-new {
    width: 75%;
    height: 48px;

    font-family: PPPangramSansRounded-Bold;
    font-size: 14px;

    background: linear-gradient(305.83deg, #ffee10 27.33%, #ffb900 86.11%);
  }
}

// =============================================
// roi-summary

.roi-summary {
  position: relative;
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0px;

  margin-top: -12px;
  padding-bottom: 16px;
}

.clicker-page {
  position: relative;
  width: 100%;
  height: 100%;

  padding: 0px 24px;
  padding-bottom: 0;

  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  // setup fullscreen safe-area
  padding-top: calc(var(--safe-top) + 0px);

  .home-disabled {
    top: 0;
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    right: 0;
    background: black;
    bottom: 0;
    z-index: 3;
  }
}

.trading-profile-user-container {
  width: 100%;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start !important;

  .trading-profile-user {
    width: 100%;
    height: 50px; // +2px of visual corection

    justify-content: start !important;
    gap: 12px;

    padding: 12px;

    border-image-source: url('../../../../assets/buttons/btn_profile_dark.svg') !important;
    border-image-width: 90px;
    border-image-slice: 28 28 28 28 fill !important; /* top | right | bottom | left | middle */

    .trading-profile-user-left {
      .image {
        width: 32px;
        height: 32px;
        img {
          width: 100%;
          border-radius: 90px;
        }
      }
    }
    .trading-profile-user-right {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 12px;

      // .user-name {
      //   font-family: PPPangramSansRounded-Bold;
      //   font-size: 14px;
      //   width: 100%;
      // }

      .user-coins {
        display: flex;
        flex-direction: row;
        gap: 4px;
        align-items: center;

        .label {
          margin-top: 3px;
          font-family: PPPangramSansRounded-Bold;
          font-size: 14px;
          color: #f5c005; // yellow
        }

        .icon {
          width: 16px;
          height: 16px;
          img {
            height: 100%;
          }
        }
      }

      .user-percent {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 2px;

        padding-top: 3px;

        // .label {
        //   font-family: PPPangramSansRounded-Medium;
        //   font-size: 11px;
        //   color: #777;
        // }

        .arrow {
          width: 10px;
          height: 8px;

          img {
            width: 100%;
          }

          &.green {
            margin-top: -14px;
            margin-right: 0px;
          }
          &.red {
            margin-top: -11px;
            margin-right: -1px;
          }
        }
        .percent {
          font-family: PPPangramSansRounded-Bold;
          font-size: 12px;
          color: white;
          &.green {
            color: #09d0a8;
          }
          &.red {
            // color: #d00948;
            color: #ef454a; // bright red
          }
        }
      }
    }
  }
}

.modal-header-icon {
  position: relative;
  width: 100%;
  height: 100%;
  // padding-top: 16px;

  img {
    width: 100%;
    height: 100%;

    &.glow {
      width: 100%;
      height: 100%;

      z-index: -1;
      position: absolute;
      filter: blur(24px);
    }
  }
}

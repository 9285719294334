// .modal-drawer.drawerTradingComingSoon {
//   .modal-drawer-box {
//     .drawer-layout {
//       .drawer-header {
//       }
//     }
//   }
// }

// ------------------------------

.drawer-trading-comingsoon-header {
  padding-bottom: 32px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  gap: 10px;

  .drawer-trading-comingsoon-icon {
    width: 180px;
    height: 180px;
  }

  .drawer-trading-comingsoon-phaze {
    font-family: PPPangramSansRounded-SemiBold;
    font-size: 26px;
    margin-top: 6px;
  }

  .drawer-trading-comingsoon-title {
    font-family: PPPangramSansRounded-Bold;
    font-size: 56px;
    margin-top: 6px;

    line-height: 56px;
    letter-spacing: -0.03em;

    background: linear-gradient(305.83deg, #ffee10 27.33%, #ffb900 86.11%);
    -webkit-text-fill-color: transparent;
    background-clip: text;
  }

  .drawer-trading-comingsoon-subtitle {
    font-family: PPPangramSansRounded-Medium;
    font-size: 14px;
    line-height: 22px;
    color: #777;
  }
}

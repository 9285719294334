// ===================================================================
// Clicker Coin Button (gradient, big button, flying numbers, riplles effect)
@function scale-color($color, $p) {
  $r1: red($color) * $p;
  $g1: green($color) * $p;
  $b1: blue($color) * $p;
  $c: 255 * $p * 0.3;
  @return rgb(round($r1 + $c), round($g1 + $c), round($b1 + $c));
}

@function build-bg-gradient($color) {
  @return radial-gradient(
    92.21% 42.54% at 50% 52.52%,
    scale-color($color, 0.8) 8%,
    scale-color($color, 0.75) 20%,
    scale-color($color, 0.6) 32%,
    scale-color($color, 0.5) 41%,
    scale-color($color, 0.35) 52%,
    scale-color($color, 0.1) 88%,
    #000000 100%
  );
}

$bg-league-1: #707070;
$bg-league-2: #8c7142;
$bg-league-3: #458f7f;
$bg-league-4: #424289;
$bg-league-4: #aaa7f0;
$bg-league-5: #d25f5f;
$bg-league-6: #2d86a5;
$bg-league-7: #5b89fe;
$bg-league-8: #be8c2f;
$bg-league-9: #631db9;

.clicker-diamond {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0;

  -webkit-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-user-callout: none;
  touch-action: none;
  -webkit-tap-highlight-color: transparent;

  .coin-container {
    z-index: 0;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    .bg-radial {
      z-index: -2;
      pointer-events: none;

      // note that original dimensions for this gradient were 430 x 932, a ratio of 2.2190
      // we are converting measures to be relative to the width of our screen
      // so radial background scales properly on any phone
      position: absolute;
      width: 100svw; // 430px;
      height: 222svw; // 932px;
      transform: translateY(-6svw);

      // first color in gradient original -> // #010101 8%,

      &.blue {
        // used for normal clicker mode
        background: radial-gradient(
          92.21% 42.54% at 50% 52.52%,
          #9fccff 8%,
          rgba(25, 97, 207, 0.9) 33.5%,
          rgba(25, 97, 207, 0.8) 54%,
          #0a2e6b 73.5%,
          #01050d 100%
        );
      }

      &.purple {
        /*used for rocketman and morse-code modes */
        background: radial-gradient(
          92.21% 42.54% at 50% 52.52%,
          #d8bcfc 8%,
          rgba(162, 95, 247, 1) 33.5%,
          rgba(113, 33, 216, 1) 54%,
          #4903a3 73.5%,
          #090310 100%
        );
      }

      &.green {
        /* currently not in use - todo: this color is wrong. we should use a green gradient here */
        background: radial-gradient(
          92.21% 42.54% at 50% 52.52%,
          #d8bcfc 8%,
          rgba(162, 95, 247, 1) 33.5%,
          rgba(113, 33, 216, 1) 54%,
          #4903a3 73.5%,
          #090310 100%
        );
      }

      &.league-1 {
        background: build-bg-gradient($bg-league-1);
      }

      &.league-2 {
        background: build-bg-gradient($bg-league-2);
      }

      &.league-3 {
        background: build-bg-gradient($bg-league-3);
      }

      &.league-4 {
        background: build-bg-gradient($bg-league-4);
      }

      &.league-5 {
        background: build-bg-gradient($bg-league-5);
      }

      &.league-6 {
        background: build-bg-gradient($bg-league-6);
      }

      &.league-7 {
        background: build-bg-gradient($bg-league-7);
      }

      &.league-8 {
        background: build-bg-gradient($bg-league-8);
      }

      &.league-9 {
        background: build-bg-gradient($bg-league-9);
      }
    }

    .hitbox {
      position: absolute;
      pointer-events: auto;
      width: 200%;
      height: 100%;
      z-index: 10;
      -webkit-user-select: none;
      user-select: none;
      -webkit-touch-callout: none;
      -webkit-user-callout: none;
      -webkit-user-select: none;
      -webkit-user-drag: none;
      -webkit-user-modify: none;
      -webkit-highlight: none;
      -webkit-user-drag: none;
      -webkit-user-modify: none;
      -webkit-highlight: none;
    }

    .coin {
      z-index: 1;
      position: relative;
      cursor: pointer;

      -webkit-user-select: none;
      user-select: none;
      -webkit-touch-callout: none;
      -webkit-user-callout: none;
      -webkit-user-select: none;
      -webkit-user-drag: none;
      -webkit-user-modify: none;
      -webkit-highlight: none;

      display: flex;
      align-items: center;
      justify-content: center;

      .gem-icon {
        position: absolute;
        width: 65%; // 55%;
        height: 65%; // 55%;
        margin-top: -5%;
        margin-left: 2%;
      }

      img {
        pointer-events: none;

        &.gem-circle {
          transition: all 0.35s ease;

          // circle size relative to vertical screen size
          width: 35svh;
          height: 35svh;

          &.morse-code {
            // circle size relative to vertical screen size
            width: 26svh;
            height: 26svh;
          }
        }

        // &.gem-icon {
        //   position: absolute;
        //   width: 65%; // 55%;
        //   height: 65%; // 55%;
        // }
      }

      &.btn-animate {
        -webkit-animation: btn-click 0.1s ease-in-out 0s 1 normal none;
        animation: btn-click 0.1s ease-in-out 0s 1 normal none;
      }
    }
  }
}

.point-up {
  z-index: 2;
  position: absolute;
  opacity: 0;

  font-family: PPPangramSansRounded-Bold;
  font-size: 28px;
  color: white;

  animation: point-up 0.4s ease-in 0s 1 normal none;

  &.boosted {
    color: #ffe50f;
  }
}

#ripple-templates {
  display: none;
}

.ripple-circles {
  z-index: 0;
  height: 90vmin;
  position: relative;
  width: 90vmin;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;

  > div {
    animation: circlesGrowAndFade 0.5s 1 ease-out;
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 50%;
    height: 100%;
    opacity: 0;
    position: absolute;
    width: 100%;
  }

  .ripple-circle-1 {
    animation-delay: 0.1s;
  }
  .ripple-circle-2 {
    animation-delay: 0.2s;
  }
  .ripple-circle-3 {
    animation-delay: 0.3s;
  }
}

// ==========================================================
// header

.modal-header.teampage {
  display: flex;
  flex-direction: column;
  align-items: center;

  padding-top: 16px;

  .modal-header-glow {
    z-index: -1;
    pointer-events: none;
    position: absolute;
    top: -160px;
    transform: scale(0.75);
  }

  .modal-header-icon img {
    width: 100px;
    height: 100px;
    border-radius: 100%;
  }

  .modal-header-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 8px;

    padding-top: 20px;

    .modal-header-title-name {
      font-family: PPPangramSansRounded-Semibold;
      font-size: 32px;
    }

    .modal-header-title-share {
      filter: invert(0.75);
      width: 32px;
      height: 32px;
      img {
        width: 32px;
        height: 32px;
      }
    }
  }

  .modal-header-subtitle {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 4px;

    padding-top: 8px;

    font-family: PPPangramSansRounded-Semibold;
    font-size: 14px;
    color: #777;

    .leaderboard {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 4px;

      img {
        width: 24px;
        height: 24px;
        margin-top: -4px;
      }
    }

    .rank {
      width: 24px;
      height: 24px;
      img {
        width: 24px;
        height: 24px;
      }
    }
  }
}

// ==========================================================
// Page

.team-page {
  width: 100%;
  height: 100%;
  padding: 0;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;

  // team info

  .team-page-info {
    display: flex;
    flex-direction: row;
    gap: 8px;
    justify-content: space-around;

    width: 100%;
    height: 76px;

    background-color: #222;
    border-radius: 12px;
    padding: 16px;

    .team-page-info-item {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: start;
      gap: 12px;

      .icon {
        width: 32px;
        height: 32px;
        img {
          width: 100%;
        }
      }
      .value {
        font-size: 18px;
        text-align: left;

        .label {
          font-size: 14px;
          color: #777;
        }
      }
    }

    .team-page-info-separator {
      width: 1px;
      height: 100%;
      background-color: #777;
      opacity: 0.5;
    }
  }

  // invite info

  .tp-invite-info {
    display: flex;
    flex-direction: column;

    .tp-invite-text {
      display: flex;
      flex-direction: row;
      align-items: start;
      gap: 8px;

      .title {
        font-family: PPPangramSansRounded-Bold;
        font-size: 14px;
        padding-top: 2px;
      }

      img {
        width: 20px;
        height: 20px;
      }

      .subtitle {
        font-size: 14px;
        padding-top: 3px;
      }
    }
  }

  // team member list
  .team-list.tp {
    background-color: rgba(255, 0, 0, 0.5);
  }
}

// ==========================================================
// Team Member Card

.team-list.teampage {
  gap: 4px;
  padding-top: 0;

  .card.team-member {
    .card-left {
      .image {
        width: 32px;
        height: 32px;
        img {
          width: 32px;
          height: 32px;
        }
      }
    }
  }
}

// ==========================================================

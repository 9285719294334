// =================================================
// trading footer. with buy/sell tabs, input and button

.trading-token-footer-container {
  z-index: 2;
  width: 100%;
  height: 295px; // note: this is set dynamically from the footer component

  transition: all 0.35s ease;

  position: fixed;
  bottom: 0;
  left: 0;

  background-color: black;

  padding: 24px;
  padding-top: 16px;

  border-top: 1px solid #444;
  border-bottom: unset;
  border-radius: 32px 32px 0 0;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  gap: 8px;

  &.noTabs {
    height: fit-content;
    padding-top: 24px;
    padding-bottom: 32px; // important for create offchainToken to have enough space at the bottom
  }

  .trading-token-footer-buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 12px;

    width: 100%;

    // todo: animate buttons in when footer gets minimized
    transition: all 0.35s ease;

    .trading-token-footer-button {
      width: 50%;
      height: 52px; // 56px;

      &.buy {
        background-color: #09d0a8;
        font-size: 16px;
        color: white;
      }
      &.sell {
        background-color: #d00948;
        font-size: 16px;
        color: white;
      }
    }
  }

  .trading-token-footer-header {
    width: 100%;
    height: 40px;
    margin-bottom: 16px;

    &.sell-header {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      height: auto;

      .trading-token-footer-title {
        flex: 1;
        text-align: left;
        padding-left: 5px;
      }

      .trading-token-footer-close {
        width: 32px;
        height: 32px;
      }

      .trading-token-footer-form-area {
        flex-basis: 100%;
        margin-top: 16px;
      }
    }

    .tabs-wrapper {
      position: relative;
      width: 100%;

      .trading-token-footer-close {
        position: absolute;
        top: 0px;
        right: 0;
        width: 32px;
        height: 32px;
        z-index: 1;

        img {
          width: 32px;
          height: 32px;
        }
      }
    }

    .trading-token-footer-title {
      font-family: PPPangramSansRounded-Bold;
      font-size: 20px;
    }
  }

  // -------------------------

  .trading-token-footer-form-area {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 12px;

    .trading-token-footer-form {
      width: 100%;
      height: 48px;
      border-radius: 90px;
      padding: 8px 16px;
      outline: 2px solid #222;
      outline-offset: -2px;

      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      gap: 4px;

      .icon-coin-left {
        width: 20px;
        height: 20px;
      }

      .ticker-name-left {
        font-family: PPPangramSansRounded-Medium;
        font-size: 14px;
        color: #777;
      }

      // .trading-input-container {}

      .user-balance {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        padding: 0;
        margin: 0;
        margin-left: 8px;

        .user-balance-title {
          font-family: PPPangramSansRounded-Medium;
          font-size: 12px;
          color: #777;
        }

        .user-balance-points {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          gap: 4px;

          .user-balance-points-icon {
            width: 14px;
            height: 14px;
          }
          .user-balance-points-label {
            font-family: PPPangramSansRounded-Semibold;
            font-size: 12px;
          }
        }
      }
    }

    .trading-token-footer-presets {
      width: 100%;

      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-evenly;
      gap: 4px;

      .trading-token-footer-preset {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        width: 100%;
        height: 36px;
        background-color: #000;
        border: 1px solid #333;
        border-radius: 90px;
        padding: 8px;

        font-family: PPPangramSansRounded-Semibold;
        font-size: 14px;

        &.selected {
          border: 1px solid #fff;
        }
      }
    }

    .trading-token-footer-fees {
      width: 100%;

      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      gap: 4px;

      &.centered {
        justify-content: center;
      }

      .trading-token-footer-slippage {
        font-family: PPPangramSansRounded-Semibold;
        font-size: 12px;
      }
    }

    .button-buy {
      width: 100%;
      height: 56px;

      // button

      .content-buy {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 4px;

        font-family: PPPangramSansRounded-Semibold;
        font-size: 18px;

        color: black;

        .icon {
          width: 20px;
          height: 20px;
          margin-top: -1px;
          img {
            width: 20px;
            height: 20px;
          }
        }
      }

      &.no-coins {
        margin-top: -25px;
      }
    }
  }

  // -------------------------

  .items-area {
    margin-top: 12px;
  }

  .trading-token-footer-fee-message-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 4px;

    font-family: PPPangramSansRounded-Medium;
    font-size: 12px;
    color: #777;

    .coin {
      width: 16px;
      height: 16px;
      img {
        width: 100%;
      }
    }

    .percent {
      font-family: PPPangramSansRounded-Semibold;
      color: white;
    }

    .value {
      font-family: PPPangramSansRounded-Semibold;
      color: white;
      padding-top: 1px;
    }
  }

  // -------------------------
}

.tabs-container {
  padding-right: 40px; // Adjust as needed
}

.drawer-layout.powerup-info {
  .drawer-header {
    padding-bottom: 32px;
    // ===============================
    // image

    // ===============================
    // title and subtitle

    .drawer-header-subtitle.powerup-info {
      color: #fff; //#999;
      padding-top: 16px;
      padding-bottom: 16px;
      line-height: 26px;
    }

    .checks-3 {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 4px;
    }

    // ===============================
    // earn

    .puc-earn {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      padding-top: 16px;
      padding-bottom: 8px;

      .puc-earn-title {
        margin-right: 8px;

        font-family: PPPangramSansRounded-Medium;
        font-size: 18px;
        color: #999;
      }

      .puc-earn-coins {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        font-family: PPPangramSansRounded-Bold;
        font-size: 18px;
        color: #f5c005;

        img {
          margin-right: 6px;
          width: 15px;
        }
      }
    }

    // ===============================
    // score

    .score {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;

      padding-top: 16px;
      padding-bottom: 16px;

      .text {
        font-family: PPPangramSansRounded-SemiBold;
        font-size: 32px;
        padding-top: 2px;
        color: white;
      }

      .icon {
        height: 30px;
        margin-left: 4px;
        img {
          height: 100%;
        }
      }
    }
  }
}

.toast-ctn {
  position: absolute;
  width: 100%;
  height: 75px;
  z-index: 99;
  bottom: 0;
  background-color: #222;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #777;
  gap: 5px;

  b {
    color: white;
  }
}

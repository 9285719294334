$blur: 11px;

.modal-drawer.drawerTeaserS3 {
  .modal-drawer-box {
    background-color: unset;
    position: absolute;
    top: 0;

    border: none;
    border-radius: 0;

    max-height: initial;
    height: 100%;
    width: 100%;

    animation: unset;

    padding: 0;
    background-color: #121212;
  }
}

.drawer-teaser-s3 {
  position: absolute;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;

  height: 100%;

  .drawer-teaser-s3-img-container {
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &.SE {
      margin-top: -17svh;
    }
    &.Mini {
      margin-top: -5svh;
    }
    &.XR {
      margin-top: -2svh;
    }

    img {
      width: 100%;
      margin-top: -10svh;
    }
  }

  .drawer-teaser-s3-content {
    position: absolute;
    top: 0;
    z-index: 1;
    height: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    gap: 8px;

    transform: scale(0.9);

    .header {
      &.SE {
        margin-top: -2svh;
      }
      &.Mini {
        margin-top: 5svh;
      }
      &.XR {
        margin-top: 9svh;
      }

      .season3 {
        font-family: PPPangramSansRounded-Bold;
        font-size: 64px;
        color: white;
      }
      .comingsoon {
        font-family: PPPangramSansRounded-Bold;
        font-size: 20px;
        color: white;
      }
    }

    .section {
      &.s1 {
        padding-top: 40svh;
      }
      &.s2 {
        padding-top: 1svh;
      }
      &.s3 {
        padding-top: 1svh;
        font-family: PPPangramSansRounded-Bold;
        font-size: 20px;
        color: white;
        strong {
          font-family: PPPangramSansRounded-Bold;
          font-size: 48px;
          color: #f5c005; // yellow'
          text-shadow: 0 0 16px rgba(57, 97, 106, 0.32);
          display: block;
          margin-top: 4px;
        }
      }
      &.s4 {
        padding-top: 3svh;
      }
    }

    .rewards {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 8px;

      font-family: PPPangramSansRounded-Bold;
      font-size: 26px;

      .label {
        strong {
          color: #f5c005; // yellow'
        }
      }
    }

    .onemorething {
      font-family: PPPangramSansRounded-Medium;
      font-size: 16px;
      color: #777;
    }

    .button-teaser {
      width: 170px;
      height: 48px;
    }
  }
}

.slideshow {
  position: absolute;
  z-index: 99999999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  color: white;

  .backdrop {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 1;
    background-color: black;
  }

  .content {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 16px;

    width: calc(100% - 16px);
    height: calc(100% - 240px - 16px);

    // setup fullscreen safe-area
    max-height: var(--safe-height);
    padding-top: calc(var(--safe-top) + 0px);

    margin: 0 8px;
    border-radius: 20px;
    overflow: hidden;

    .image-container-tall {
      img {
        height: 65svh;
      }
    }

    .image-container-fit-width {
      img {
        width: 100%;
      }
    }
  }

  .btn-slideshow {
    transition: all 0.5s ease;
    width: 120px;
    height: 52px; // 56px;

    &.full {
      width: 100%;
    }
  }
}

// ==========================================
// Footer Tutorial

.slideshow-footer-tutorial {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 240px;
  background-color: black;
  padding: 32px 32px;

  border: 1px solid #444;
  border-bottom: unset;
  border-radius: 32px 32px 0 0;

  .layout {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 16px;

    width: 100%;

    .top {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 24px;

      .left {
        img {
          width: 100px;
          height: 100px;
        }
      }

      .right {
        .title {
          font-family: PPPangramSansRounded-Bold;
          font-size: 4.5vw; // 20px;
          line-height: 5.8vw; //24px;
          text-align: left;
          padding-bottom: 8px;
        }

        .description {
          font-family: PPPangramSansRounded-Medium;
          font-size: 3.8vw; // 15px;
          line-height: 5.3vw; // 22px;
          text-align: left;
        }
      }
    }

    .bottom {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      gap: 12px;

      width: 100%;

      .left {
        .dots {
          width: 180px;

          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: start;
          gap: 10px;

          .dot {
            transition: all 0.5s ease;

            width: 12px;
            height: 12px;
            border-radius: 20px;
            background-color: white;
            opacity: 0.5;

            &.active {
              opacity: 1;
              width: 48px;
            }
          }
        }
      }

      .right {
        .button {
          width: 120px;
          height: 56px;
        }
      }
    }
  }
}

// ==========================================
// Footer Trading

.slideshow-footer-trading {
  position: absolute;
  bottom: 0;
  width: 100%;

  // note: height needs to be omitted for things to work properly
  // height: fit-content; // 290px;

  background-color: black;
  padding: 24px 24px;
  padding-top: 28px;
  padding-bottom: 40px;

  // note: removing drawer-like outline, makes screens look quite better and more 'fullscreen'
  // border: 1px solid #444;
  // border-bottom: unset;
  // border-radius: 32px 32px 0 0;

  .layout {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 16px;

    width: 100%;
    height: 100%;

    .title {
      font-family: PPPangramSansRounded-Bold;
      font-size: 6.5vw;
      line-height: 7vw;
      text-align: center;
    }

    .description {
      display: table;
      width: 100%;

      font-family: PPPangramSansRounded-Medium;
      font-size: 3.8vw;
      line-height: 5.3vw;
      text-align: center;
      color: #777;

      .subtitle {
        display: table-cell;
        vertical-align: middle;
      }
    }

    .dots {
      width: 180px;

      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 10px;

      padding-bottom: 8px;

      .dot {
        transition: all 0.5s ease;

        width: 12px;
        height: 12px;
        border-radius: 20px;
        background-color: white;
        opacity: 0.5;

        &.active {
          opacity: 1;
          width: 48px;
        }
      }

      .button {
        width: 120px;
        height: 56px;
      }
    }
  }
}

.cms {
  display: flex;
  flex-direction: column;
  padding: 24px 0;

  .cms-card {
    border: 1px solid white;
    border-radius: 8px;
    padding: 12px;
    display: flex;
    flex-direction: column;
    margin: 8px 0;
  }
}

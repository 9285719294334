// =============================================
// ProfilePage

.page.profile {
  gap: 20px;

  // setup fullscreen safe-area
  padding-top: calc(var(--safe-top) + 16px);
}

.profile-header {
  width: 100%;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  gap: 16px;

  padding-top: 8px;

  .profile-header-left {
    .profile-user-image {
      width: 64px;
      height: 64px;
      border-radius: 90px;
      img {
        width: 100%;
        border-radius: 90px;
      }
    }
  }

  .profile-header-right {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    gap: 12px;

    height: 100%;
    width: 100%;

    .profile-user-name {
      margin-left: 4px; // visual correction
      font-family: PPPangramSansRounded-Bold;
      font-size: 20px;
      color: white;
      text-align: left;
    }

    .profile-follow-info {
      margin-left: 4px; // visual correction

      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: start;
      gap: 16px;

      .section {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: start;
        gap: 4px;

        .value {
          font-family: PPPangramSansRounded-Bold;
          font-size: 14px;
          color: white;
        }

        .label {
          font-family: PPPangramSansRounded-SemiBold;
          font-size: 14px;
          color: #777;
        }
      }
    }
  }
}

.profile-buttons {
  width: 100%;

  margin-top: -2px; // visual correction
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 8px;

  .profile-button {
    font-family: PPPangramSansRounded-SemiBold;
    font-size: 13px;
    width: 100%; // 100px;
    height: 36px;

    &.share {
      border: 1px solid rgba(255, 255, 255, 0.35);
      width: 100%;
    }

    &.connect-wallet {
      background: linear-gradient(305.83deg, #ffee10 27.33%, #ffb900 86.11%);
    }
  }
}

// had to create this container wrapper, otherwise chart in profile becomes weirdly scrollable under bottom tabs
.chart-container-wrapper {
  width: 100%;
  height: fit-content;
  // background-color: red;
}

// =============================================
// ProfileTokenList

.items-area {
  margin-top: 12px;
}

.profile-items-list {
  margin-top: 8px;
  margin-bottom: 50px; // account for tiktok footer
}

.separator {
  width: 100%;
  height: 0;
  border-bottom: 1px rgba(255, 255, 255, 0.3) solid;
  padding-bottom: 12px;
}

// =============================================
// Profile header boxes

.profile-header-boxes {
  width: 100%;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 6px;

  .profile-header-box {
    width: 100%;
    height: 58px;

    padding: 12px;

    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    gap: 6px;

    background-color: #222;
    border-radius: 12px;

    .title {
      font-family: PPPangramSansRounded-SemiBold;
      font-size: 11px;
      color: #999;
      text-align: left;
    }

    .value-area {
      display: flex;
      flex-direction: row;
      align-items: center; // end;
      justify-content: start;
      gap: 6px;

      .value {
        font-family: PPPangramSansRounded-SemiBold;
        font-size: 18px;
        color: white;
      }

      .profile-header-box-percent {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 4px;

        margin-top: 3px;

        .label {
          font-family: PPPangramSansRounded-Medium;
          font-size: 12px;
          color: #777;
        }

        .arrow {
          width: 10px;
          height: 8px;

          img {
            width: 100%;
          }

          &.green {
            margin-top: -14px;
            margin-right: 0px;
          }
          &.red {
            margin-top: -14px;
            margin-right: -1px;
          }
        }
        .percent {
          font-family: PPPangramSansRounded-Bold;
          font-size: 12px;
          color: white;
          &.green {
            color: #09d0a8;
          }
          &.red {
            // color: #d00948;
            color: #ef454a;
          }
        }
      }
    }
  }
}

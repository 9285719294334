// ===================================================================
// Astronaut (rocketman)

@keyframes flyOver {
  0% {
    top: 100%;
    left: -100%;
  }
  100% {
    top: -50%;
    left: 200%;
  }
}

.astronaut-c {
  pointer-events: none;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  overflow: hidden;

  .astronaut {
    animation: flyOver 5s 1 ease-in;
    width: 123px;
    height: 117px;
    position: relative;
    top: 100%;
    left: -100%;
  }

  .char {
    position: relative;
    z-index: 3;
  }

  .flame-c {
    transform: rotate(240deg) scaleX(0.2) scaleY(0.5);
  }

  .flame {
    animation: burn 1s infinite;
    width: 30vmin;
    aspect-ratio: 1;
    background: radial-gradient(
        100% 100% at 90% 90%,
        #251c27,
        #251c2733 20%,
        #251c2700 50%
      ),
      radial-gradient(
        farthest-side at 110% 120%,
        #251c27,
        #631,
        #cb6c3b88,
        #0000
      ),
      radial-gradient(at 100% 100%, #fc08, #cb6c3b, #eebd7600 60%),
      linear-gradient(135deg, #fff0 20%, #ff0);
    background-color: #ffe;
    border-radius: 20% 87% 45% 85%;
    box-shadow: inset 2vmin 2vmin 2vmin -1.5vmin #f808,
      inset -1vmin -1vmin 5vmin -3vmin #00f7,
      inset 0vmin -1vmin 5vmin -3vmin #00f8,
      inset -1vmin -1vmin 2vmin -2vmin #251c27,
      inset -1vmin -1vmin 3vmin -1vmin #251c27, inset -1vmin -1vmin 2vmin #fc08,
      -0.5vmin -0.5vmin 1vmin #ff08, -1vmin -1vmin 2vmin #ce8c47,
      -2vmin -2vmin 10vmin 1vmin #251c27, -6vmin -6vmin 35vmin 3vmin #fa06;
    filter: blur(0.1vmin);
    transform: rotate(45deg);
    /* demo */
    position: absolute;
    top: -120px;
    left: 245px;
  }
}

// @keyframes burn {
//   0%, 100% { border-radius: 5% 87% 45% 85%; width: 30vmin; }
//   10% { border-radius: 5% 85% 49% 82%; }
//   20% { border-radius: 0% 85% 45% 87%; width: 33vmin; }
//   30%, 90% { border-radius: 10% 85% 80% 82%; }
//   40% { border-radius: 0% 85% 45% 87%; width: 35vmin; }
//   50% { border-radius: 2% 87% 42% 90%; }
//   60% { border-radius: 5% 97% 45% 88%; }
//   70% { border-radius: 2% 87% 102% 90%; width: 33vmin}
//   80% { border-radius: 5% 97% 45% 88%; }
// }

@keyframes burn {
  0%,
  100% {
    border-radius: 5% 87% 45% 85%;
    width: 110px;
  }
  10% {
    border-radius: 5% 85% 49% 82%;
  }
  20% {
    border-radius: 0% 85% 45% 87%;
    width: 115px;
  }
  30%,
  90% {
    border-radius: 5% 85% 49% 82%;
  }
  40% {
    border-radius: 0% 85% 45% 87%;
    width: 125px;
  }
  50% {
    border-radius: 2% 87% 42% 90%;
  }
  60% {
    border-radius: 5% 97% 45% 88%;
  }
  70% {
    border-radius: 2% 87% 42% 90%;
    width: 115px;
  }
  80% {
    border-radius: 5% 97% 45% 88%;
  }
}

.match::after {
  content: '';
  display: block;
  width: 6vmin;
  height: 10vmin;
  background: linear-gradient(45deg, #f002, #fff0),
    linear-gradient(to right, #d68356, #e5653e 3%, #d0363b 20%, #251c27);
  top: 24vmin;
  left: 24vmin;
  border-radius: 100% / 120% 80% 80% 120%;
  position: absolute;
  transform: translate(-50%, -50%) rotate(45deg);
  box-shadow: 0 0 5vmin 3vmin #251c27aa, inset -1vmin 0 1.5vmin #251c27;
}

.match::before {
  content: '';
  display: block;
  width: 20vmin;
  height: 3vmin;
  background: linear-gradient(to right, #d605, #321, #251c27);
  top: 32vmin;
  left: 32vmin;
  position: absolute;
  transform: translate(-50%, -50%) rotate(45deg);
  box-shadow: 0 0 5vmin 3vmin #251c27aa, inset -1vmin 0 1.5vmin #251c27aa;
}

// =============================================
// container for whole tabs and lists with items

.trading-tabs-container {
  width: 100%;
  // overflow-x: hidden;
  margin-top: -8px;
}

// =============================================
// container for list with items on each tab

.items-area {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 24px;

  .trading-items-list {
    margin-top: -8px;
  }

  .items-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 8px;

    width: 100%;
    height: fit-content;
    border-radius: 12px;

    &.transactions {
      margin-bottom: 4px;
    }
  }
}

.modal-drawer.leaderboard {
  .modal-drawer-box {
    height: 73svh;
  }

  .drawer-layout {
    .drawer-header {
      padding-bottom: 0;

      // ===============================
      // image

      .drawer-header-icon {
        img {
          width: 56px;
          height: 56px;
        }
      }

      // ===============================
      // title and subtitle

      .drawer-header-title {
        font-family: PPPangramSansRounded-SemiBold;
        font-size: 32px;
        padding-top: 12px;
      }

      // ===============================
      // team list

      .team-list-container {
        width: 100%;
        height: calc(73svh - 180px);
        overflow-y: scroll;
        margin-top: 24px;

        .team-list {
          padding-top: 0;

          .card.team {
            .card-left {
              .image {
                width: 48px;
                height: 48px;
                img {
                  border-radius: 100%;
                  width: 48px;
                  height: 48px;
                }
              }

              .info {
                width: calc(100svw - 190px);
              }
            }
          }
        }
      }

      // ===============================
    }
  }
}

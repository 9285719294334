.whats-new-badge {
  position: absolute;
  // background: linear-gradient(to right, #ffee10 0%, #ffb900 100%);
  background: linear-gradient(to right, #f00 0%, #9a0000 100%);
  border-radius: 50%;

  border: 1px solid #ffffff33;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  width: 26px;
  height: 26px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .badge-label {
    font-size: 14px;
    color: #fff;
    margin-top: 2px;
  }
}

.whats-new-badge.alert {
  width: 13px;
  height: 13px;
  .badge-label {
    color: #0000;
  }
}

.btn-clicker.footer {
  .whats-new-badge {
    top: -5px; // 2px;
    right: 6px;
  }
  .whats-new-badge.alert {
    top: 7px;
    right: 20px;
  }
}

.tabs .whats-new-badge {
  top: -5px; // -11px;
  right: -5px;
  min-width: 18px;
  min-height: 18px;

  .badge-label {
    font-size: 12px;
    margin-top: 1px;
  }
}

.powerup-card .whats-new-badge {
  right: -2px;
  top: -2px;
  min-width: 14px;
  min-height: 14px;
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.fade-out {
  animation: fadeOut 1s forwards;
}

.visited {
  .whats-new-badge {
    animation: fadeOut 0.3s forwards;
  }
}

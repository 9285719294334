// ===================================================================
// Clicker Footer (energy bar and buttons)

footer {
  z-index: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: start;
  justify-content: center;
  gap: 3svh; // 1.5svh; // 16px;

  box-sizing: border-box;
}

.clicker-footer-buttons {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-around;
  gap: 8px;

  border-image-source: url('../../../../assets/buttons/bottom_container.svg');
  border-image-width: 80px; // 22px;
  border-image-slice: 40 40 40 40 fill; /* top | right | bottom | left | middle */

  // we need to ignore the padding of parent page
  margin-left: -24px;
  margin-right: -24px;
  margin-bottom: 0;
  width: calc(100% + 48px);

  padding-left: 18px;
  padding-right: 18px;

  padding-bottom: 4px;
  &.telegram-ios {
    padding-bottom: 16px;
  }
  &.telegram-android {
    padding-bottom: 8px;
  }

  .clicker-footer-buttons-centered-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: space-around;
    gap: 8px;
    // max-width: 640px; // 414px;
  }
}

.progress-area {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: start;
  justify-content: space-between;
  gap: 8px;
}

.progress-info {
  width: 160px;
  display: flex;
  flex-direction: column;
  gap: 6px; // 10px;

  .progress-info-top {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: start;
    gap: 6px;

    .progress-info-icon {
      width: 22px;
      height: 22px;
      img {
        width: 22px;
        height: 22px;
      }
    }

    .progress-info-label {
      font-family: PPPangramSansRounded-Bold;
      font-size: 14px;
      padding-top: 2px;
      box-sizing: border-box;

      .p-1 {
        color: white;
      }
      .p-2 {
        font-family: PPPangramSansRounded-Bold;
        color: white;
      }
      .p-3 {
        color: rgba(255, 255, 255, 0.5);
      }
    }
  }

  .progress-bar {
    position: relative;
    width: 100%;
    height: 9px;
    border-radius: 100px;

    .progress-bar-bg {
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: 100px;
      background-color: rgba(255, 255, 255, 0.5);
    }

    .progress-bar-value {
      position: absolute;
      width: 50%;
      height: 100%;
      border-radius: 100px;
      background-color: rgba(255, 255, 255, 1);
    }
  }
}

// =============================================
// Tiktok Page

// .page-container {
//   background-color: orange;
// }

.page.tiktok {
  margin: 0;
  padding: 0;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 0;

  background-color: transparent;

  height: 100%;

  overflow-y: hidden;
  overflow-x: hidden;
}

// =============================================
// Feed Item - Top UI (Fixed outside carousel)

.feed-ui-top {
  pointer-events: none;
  z-index: 996;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;

  // setup fullscreen safe-area
  padding-top: calc(var(--safe-top) + 0px);

  &.invisible {
    visibility: hidden;
  }

  // new top gradient
  .feed-ui-top-gradient {
    pointer-events: none;
    z-index: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 78px; // 98px; // 198px;
    opacity: 0.8;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 1) 0%,
      rgba(0, 0, 0, 0.5) 50%,
      rgba(0, 0, 0, 0) 100%
    );

    // setup fullscreen safe-area -> note that this will be zero when there is no --safe-top available (api less than 8.0)
    // todo: need to find a final solution here, but ok for now.
    height: calc(var(--safe-top) + 78px + 40px);
  }

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;

  .feed-ui-top-content {
    pointer-events: none;
    z-index: 1;
    display: flex;
    flex-direction: row;
    align-items: start;
    justify-content: space-between;

    width: 100%;
    padding: 16px;
    padding-top: 8px; // setup fullscreen safe-area

    transition: opacity 350ms ease-out;

    .feed-item-ui-search {
      justify-content: end;
      width: 100px;

      // setup fullscreen safe-area
      margin-top: 4px;
      height: 40px;

      .feed-item-ui-search-button {
        pointer-events: auto;
        width: 30px;
        height: 30px;
        img {
          height: 100%;
        }
      }
    }
  }

  .feed-item-user {
    pointer-events: auto;
    justify-content: start;
    gap: 4px;
    width: 100px;

    // setup fullscreen safe-area
    margin-top: 6px;
    height: 40px;

    // coin icon
    .feed-item-user-left {
      .image {
        width: 32px;
        height: 32px;
        img {
          width: 100%;
          border-radius: 90px;
        }
      }
    }

    // coins value and percent roi
    .feed-item-user-right {
      display: flex;
      flex-direction: column;
      align-items: start;
      justify-content: center;

      .user-coins {
        font-family: PPPangramSansRounded-Bold;
        font-size: 16px;
        text-align: left;
      }

      .user-percent {
        font-family: PPPangramSansRounded-Bold;
        font-size: 11px;
        color: white;
        &.green {
          color: #09d0a8;
        }
        &.red {
          color: #ef454a; // bright red
        }
      }
    }
  }

  .feed-item-ui-categories {
    pointer-events: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 4px;

    .feed-item-ui-category {
      height: 40px;
      padding: 0 8px;

      .feed-item-ui-category-label {
        font-family: PPPangramSansRounded-Bold;
        font-size: 16px;
        color: white;
        opacity: 0.5;

        border-bottom: unset;
      }

      &.selected {
        .feed-item-ui-category-label {
          opacity: 1;
          border-bottom: 1px solid white;
        }
      }
    }
  }
}

// =============================================
// Carousel / Feed

.carousel-container {
  width: 100%;
  height: 100%;
  position: relative;

  &.swipe-enabled {
    pointer-events: auto;
  }

  &.swipe-disabled {
    pointer-events: none;
  }

  overflow: hidden;
  margin-top: 0px;

  .empty-carousel {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
  }

  .carousel-root {
    background-color: transparent;
    width: 100%;
    height: 100%;

    // important: note that the carousel slider (FeedItem.scss)
    // needs to be 100svh based absolute size for carousel to render properly
    .carousel {
      button {
        display: none;
      }

      // this is the slide list wrapper
      .slider-wrapper {
        // this is the slide list
        ul {
          padding: 0;
          margin: 0;

          // this is each slide
          li {
            list-style: none;
          }
        }
      }
    }
  }
}

// =============================================
// Feed Item (slide)

$footer-height-desktop: 72px;
$footer-height-mobile: 84px;

.feed-item {
  position: relative;
  overflow: hidden;

  .tiktok-tap-game {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }

  // feed slides need to be fullscreen height minus the height of the footer
  height: calc(100svh - #{$footer-height-desktop});
  &.telegram-ios {
    height: calc(100svh - #{$footer-height-mobile});
  }

  font-family: PPPangramSansRounded-Bold;
  font-size: 20px;
  color: white;

  display: flex;
  flex-direction: column;
  align-items: end;
  justify-content: center;
  gap: 0;

  .feed-item-image {
    transition: transform 200ms ease-in-out;
    width: 100%;
    height: 100%;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .feed-item-media-container {
    position: relative;
    width: 100%;
    height: 100%;
  }

  .feed-item-video {
    transition: transform 200ms ease-in-out;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .feed-ad-title-description {
    position: absolute;
    text-align: left;
    bottom: 12px;
    left: 3%;
    width: 94%;
    padding: 4px;
    z-index: 2;
  }

  .feed-buttons {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    gap: 8px;
    margin-top: 8px;
    height: 56px;

    z-index: 3;

    .feed-ad-button {
      padding: 12px;
      font-size: 15px;
      height: 56px;

      &.disabled {
        opacity: 0.6;
      }
    }

    .feed-ad-btn-copy {
      border-image-source: url('../../../assets/buttons/btn_profile_dark.svg') !important;
      border-image-width: 90px;
      border-image-slice: 28 28 28 28 fill !important; /* top | right | bottom | left | middle */

      position: relative;

      width: 90px;
      height: 56px;

      .feed-ad-copy-success {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 8px;

        border-radius: 20px;
        background-color: black;
        border: 1px solid #222;

        position: absolute;
        top: -44px;
        left: -32px; // otherwise disappears under page overflow horizontal padding...

        padding: 6px;
        padding-right: 10px;
        box-sizing: border-box;

        .feed-ad-copy-success-icon {
          width: 20px;
          height: 20px;
          img {
            width: 20px;
            height: 20px;
          }
        }

        .feed-ad-copy-success-text {
          font-family: PPPangramSansRounded-Medium;
          font-size: 13px;
          color: white;
          padding-top: 2px;
          box-sizing: border-box;
        }
      }
    }
  }

  .feed-ad-countdown {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 4px; /* Adjust the height as needed */
    background-color: rgba(
      0,
      0,
      0,
      0.2
    ); /* Background color of the progress bar container */
    overflow: hidden;
  }

  .feed-ad-progress-bar {
    position: absolute;
    z-index: 2;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #fff; /* Color of the progress bar */
    transition: width 0.1s linear;
  }

  .feed-ad-sponsored {
    display: inline-flex;
    align-items: center;
    justify-content: center;

    border: 1px solid rgba(255, 255, 255, 0.5);
    border-radius: 900px;

    // @note: gradient not working as it removes the border radius!
    // border-image: linear-gradient(
    //   to right,
    //   rgba(255, 255, 255, 0.2) 0%,
    //   rgba(255, 255, 255, 0.6) 55%,
    //   rgba(255, 255, 255, 1) 80%,
    //   rgba(255, 255, 255, 0.2) 100%
    // );
    // border-image-slice: 1;

    width: 112px;
    height: 32px;
    left: 16px;
    top: 606px;

    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);

    font-family: PPPangramSansRounded-Bold;
    font-size: 12px;
    color: #fff;

    height: 32px;
    margin-bottom: 12px;
    padding: 16px 19px;

    z-index: 1;
  }

  .feed-ad-icon {
    width: 15px;
    height: 15px;
    margin-right: 8px;
  }

  .feed-ad-title {
    font-family: PPPangramSansRounded-Bold;
    font-size: 18px;
    text-align: left;
    color: white;
    margin-bottom: 8px;
    text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.9);
  }

  .feed-ad-description {
    font-family: PPPangramSansRounded-Regular;
    font-size: 12px;
    text-align: left;
    color: white;
    opacity: 60%;
    line-height: 20px;
    text-shadow: 0px 0px 2px rgba(0, 0, 0, 1);
  }

  // for testing purposes
  .debug-label {
    pointer-events: none;
    z-index: 9999;
    position: absolute;
    left: 100px;
    top: 100px;
    font-family: PPPangramSansRounded-Bold;
    font-size: 14px;
    color: red;

    text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);
  }

  // new bottom gradient
  .feed-item-bottom-gradient {
    pointer-events: none;
    z-index: 0;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 440px; // 198px;
    opacity: 1; // 0.8;
    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 1) 0%,
      rgba(0, 0, 0, 0.5) 50%,
      rgba(0, 0, 0, 0) 100%
    );
  }
}

// =============================================
// Feed Item - Bottom UI

.feed-item-ui-bottom {
  pointer-events: none;

  z-index: 3;
  position: absolute;

  width: 100%;
  height: 280px; // 440px;
  bottom: 0;

  transition: opacity 350ms ease-out;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: end;

  .feed-item-ui-content {
    pointer-events: none;
    position: relative;

    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: end;
    gap: 12px;

    width: 100%;
    padding: 16px;

    .label {
      font-family: PPPangramSansRounded-SemiBold;
      font-size: 14px;
      color: white;
      width: fit-content;

      &.tickerName {
        font-family: PPPangramSansRounded-Bold;
        font-size: 16px;
        color: white;
      }

      &.owners {
        font-family: PPPangramSansRounded-SemiBold;
        font-size: 12px;
        color: white;
      }

      &.tokenName {
        font-family: PPPangramSansRounded-SemiBold;
        font-size: 12px;
        color: white;
        opacity: 0.5;
        padding-left: 4px;
      }

      &.dot {
        font-family: PPPangramSansRounded-Bold;
        font-size: 14px;
        color: white;
        opacity: 0.5;
      }

      &.holders {
        font-family: PPPangramSansRounded-SemiBold;
        font-size: 12px;
        color: white;
      }
    }

    .row {
      width: 100%;

      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      gap: 6px;

      // &.token {}
      // &.owners {}
      // &.holders {}

      &.left {
        justify-content: start;
      }
      &.right {
        justify-content: end;
      }
      &.space-between {
        justify-content: space-between;
        pointer-events: auto;
      }
    }

    .box {
      background-color: rgba(16, 16, 16, 0.7);

      &.transparent {
        background-color: unset;
        padding: 0;
        gap: 6px;
      }

      &.inner {
        background-color: rgba(255, 255, 255, 0.25);
      }

      border-radius: 16px;
      padding: 6px 12px;
      width: fit-content;

      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 8px;

      flex-shrink: 0;

      img {
        width: 16px !important;
        height: 16px !important;
        border-radius: 90px;
      }
    }

    .item {
      pointer-events: auto;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: start;
      gap: 8px;
      flex-shrink: 0;

      .item-circle {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-items: center;
        // gap: -8px;

        .item-image {
          width: 40px;
          height: 40px;
          overflow: hidden;
          border-radius: 90px;

          img {
            width: 100%;
            object-fit: contain;
            object-position: center;
            display: block;
            transform: translateY(-25%);
          }
        }

        .item-position {
          z-index: 1;
          font-family: PPPangramSansRounded-Bold;
          font-size: 11px;

          background-color: black;
          border-radius: 90px;

          width: object-fit;
          max-width: 100%; // 40px;

          margin-top: -11px;
          padding: 0px 6px;
          padding-top: 1px;

          &.top3 {
            background: linear-gradient(
              305.83deg,
              #ffee10 27.33%,
              #ffb900 86.11%
            );
            color: black;
          }
        }
      }

      .info {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: start;
        gap: 4px;
        margin-top: 2px;
      }
    }

    .button-more {
      width: 66px;
      height: 32px;
      font-size: 13px;
    }

    .mini-chart {
      pointer-events: auto;
      width: 80px;
      height: 40px;
      padding: 0; // 8px;
      // background-color: rgba(0, 0, 0, 0.5);
      // border-radius: 12px;
    }

    .value-area {
      pointer-events: auto;

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: end;
      gap: 1px;

      .price-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: end;
        gap: 4px;

        .label {
          font-family: PPPangramSansRounded-Bold;
          font-size: 16px;
          margin-top: 3px;
        }

        .coin {
          height: 16px;
          width: 16px;
          img {
            height: 100%;
          }
        }
      }

      .percent-container {
        display: flex;
        flex-direction: row;
        gap: 4px;
        align-items: start;
        justify-content: end;

        .arrow {
          width: 10px;
          height: 8px;

          img {
            width: 100%;
          }

          &.green {
            margin-top: -7px;
          }
          &.red {
            margin-top: -7px;
          }
        }

        .percent {
          font-family: PPPangramSansRounded-Bold;
          font-size: 12px;
          color: white;
          &.green {
            color: #09d0a8;
          }
          &.red {
            color: #d00948;
          }
        }
      }
    }

    .btn,
    .feed-item-user,
    .feed-item-ui-search-button,
    .feed-item-ui-categories,
    .buysell-footer-buttons {
      pointer-events: auto;
    }
  }
}

// =============================================
// Buy/Sell Footer (part of bottom-ui-content)

.buysell-footer {
  z-index: 5;
  width: 100%;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 6px;

  transition: opacity 350ms ease-out;

  .buysell-footer-buttons {
    border-image-source: url('../../../assets/buttons/btn_profile_dark.svg') !important;
    border-image-width: 90px;
    border-image-slice: 28 28 28 28 fill !important; /* top | right | bottom | left | middle */

    width: 100%;
    height: 50px;

    margin-top: 2px;
    padding: 8px;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 0;

    .buysell-footer-button {
      width: 50%;

      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 8px;

      img {
        width: 22px;
        height: 22px;
      }
      .label {
        font-family: PPPangramSansRounded-Bold;
        font-size: 16px;
        color: white;
      }
    }

    .separator {
      width: 1px;
      height: 100%;
      background-color: rgba(255, 255, 255, 0.25);
    }
  }
}

// =============================================
// Sidebar

.sidebar {
  // background-color: red;
  z-index: 4;
  position: absolute;
  right: 16px;

  // todo: because owned/points goes to double line
  // todo: we need to make vertical position relative to ui height
  bottom: 165px;

  width: 36px;

  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  justify-content: end;
  gap: 16px;

  .sidebar-item {
    cursor: pointer;
    pointer-events: auto;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: end;
    gap: 8px;

    .sidebar-image {
      width: fit-content;
      height: fit-content;
      img {
        width: unset;
        height: unset;
      }
    }

    &.author {
      img {
        width: 36px;
        height: 36px;
        border-radius: 90px;
        box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
      }
    }
  }

  .label {
    font-family: PPPangramSansRounded-Bold;
    font-size: 12px;
    color: white;
    width: 2rem; /* Set a consistent width for the label */
    text-align: center; /* Center the text inside the label */
    display: block; /* Ensure the label remains on its own line */
    margin: 0 auto; /* Center the label horizontally within its container */
  }
}

// =============================================

.drawer-reward-summary {
  .btn-normal {
    img {
      width: 30px;
      height: 30px;
      margin: 0 7px;
    }
    &.disabled {
      opacity: 0.6;
    }
  }

  .drawer-header-title {
    font-family: PPPangramSansRounded-SemiBold;
    font-size: 32px;
    padding-top: 0px;
    margin-top: -44px;
    z-index: 1;
  }

  .subtitle {
    opacity: 0.6;
    font-size: 16px;
    line-height: 20px;
    padding: 5px 30px 15px;
  }

  .points-container {
    padding-bottom: 15px;
  }
  .points-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 0;
  }

  .description {
    font-size: 16px;
    opacity: 0.6;
  }

  .points {
    display: flex;
    align-items: center;
    font-size: 16px;
    gap: 0;
  }

  .coin-icon {
    width: 24px;
    height: 24px;
    margin-left: 3px;
  }
}

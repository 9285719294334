$blur: 11px;

.modal-drawer.drawerFtueShare {
  .modal-drawer-backdrop {
    -webkit-backdrop-filter: blur($blur) !important;
    backdrop-filter: blur($blur) !important;
    background-color: rgba(0, 0, 0, 1) !important;
  }

  .modal-drawer-box {
    background-color: unset; // rgba(255, 0, 0, 0.5);
    position: absolute;
    top: 0;

    border: none;
    border-radius: 0;

    height: 100%;
    width: 100%;

    animation: unset;
  }
}

.drawer-ftue-share {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;

  height: 100%;

  // ========================================================================
  // Error State

  .drawer-ftue-share-error-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 32px;

    .drawer-ftue-share-error-title {
      font-size: 16px;
    }
    .drawer-ftue-share-error-list {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 12px;

      .drawer-ftue-share-error-message {
        font-size: 10px;
        opacity: 0.5;
        text-align: left;
      }
    }
    .drawer-ftue-share-error-button {
      height: 52px;
      width: 200px;
      margin-top: 8px;
    }
  }

  // ========================================================================
  // Loading State

  .drawer-ftue-share-loading-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    // gap: 32px;

    .drawer-ftue-share-loading {
      width: 100%;
      height: 400px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: start;
      gap: 32px;

      padding: 32px;

      .drawer-ftue-share-checking {
        font-family: PPPangramSansRounded-Bold;
        font-size: 24px;
        color: white;
        line-height: 1.2;
        text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
      }

      .drawer-ftue-share-loading-bars {
        width: 100%;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 20px;

        .progress-bar-container-v {
          width: 100%;

          display: flex;
          flex-direction: column;
          align-items: start;
          justify-content: center;
          gap: 8px;

          .progress-bar-text {
            font-family: PPPangramSansRounded-SemiBold;
            font-size: 13px;
            color: white;
            line-height: 1.2;
            text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
            opacity: 0.5;
          }

          .progress-bar-container-h {
            width: 100%;
            height: 24px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            gap: 8px;

            .progress-bar {
              width: 100%;

              .progress-bar-bg {
                background-color: #222;
              }
              .progress-bar-value {
                &.yellow {
                  background-color: #f5c005; // yellow'
                }
              }
            }

            .progress-bar-icon {
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              gap: 8px;
              img {
                width: 100%;
              }
            }
          }
        }
      }
    }
  }

  // ========================================================================
  // Top - Header

  .drawer-ftue-share-header {
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 8px;

    .drawer-ftue-share-header-title {
      font-family: PPPangramSansRounded-Bold;
      font-size: 19px;
      color: white;
      line-height: 1.2;
      text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
      // opacity: 0.5;
    }
    .drawer-ftue-share-header-subtitle {
      font-family: PPPangramSansRounded-SemiBold;
      font-size: 19px;
      color: white;
      line-height: 1.2;
      text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
      margin-bottom: 10px;
    }
  }

  // ========================================================================
  // Center - Content

  .drawer-ftue-share-content {
    height: fit-content;
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 16px;

    .drawer-ftue-share-header-message {
      font-family: PPPangramSansRounded-SemiBold;
      font-size: 24px;
      color: white;
      line-height: 1.2;
      text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
    }

    // .drawer-ftue-share-token-container {
    //   position: relative;

    //   display: flex;
    //   flex-direction: column;
    //   align-items: center;
    //   justify-content: center;
    //   gap: 24px;

    //   .drawer-ftue-share-token-image {
    //     position: absolute;
    //     margin-top: -50px;
    //     width: 192px;
    //     height: 220px;
    //     border-radius: 20px;

    //     overflow: hidden;

    //     img {
    //       object-fit: cover;
    //       object-position: center;
    //       width: 100%;
    //       transform: translateY(-25%);
    //     }
    //   }
    // }

    .drawer-ftue-header-icon {
      display: flex;
      align-items: center;
      justify-content: center;

      .img-header {
        width: 200px;
        height: 200px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        border: 3px solid white;
        position: relative;

        .img-container {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 92%;
          height: 92%;
          overflow: hidden;
          border-radius: 50%;

          img {
            width: 120%;
            height: 120%;
          }
        }

        .overlay-img-header {
          position: absolute;
          width: 40px;
          height: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
          overflow: hidden;
          border-radius: 50%;
          border: 4px solid black;
          padding: 6px;
          background-color: white;
          bottom: 0;
          right: 0;

          .overlay-img-container {
            overflow: hidden;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
              width: 100% !important;
              height: 100% !important;
            }
          }
        }
      }
    }

    .drawer-ftue-share-token-value-text {
      font-family: PPPangramSansRounded-Bold;
      font-size: 40px;
      color: white;
      line-height: 1.2;
      text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);

      padding-top: 7px;
    }
  }

  // ========================================================================
  // Bottom - Buttons

  .drawer-ftue-share-buttons {
    width: 100%;
    // margin-bottom: 24px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;

    // give and get

    .drawer-ftue-share-giveAndGet {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 10px;

      margin-top: 8px;
      margin-bottom: 16px;

      .drawer-ftue-share-giveAndGet-text {
        font-family: PPPangramSansRounded-SemiBold;
        font-size: 18px;
        color: white;
        text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);

        &.separator {
          opacity: 0.5;
          width: fit-content;
          height: 72%;
        }
      }
    }

    .drawer-ftue-share-button {
      max-width: 200px;
      height: 56px;
      margin-bottom: 20px;
    }

    // ========================================================================

    // .gameover-button {
    // &.white {
    //   background-color: white;
    //   border: 1ps solid black;
    //   color: black;
    //   border-radius: 90px;

    //   border-image-source: unset;
    // }

    // &.dark {
    //   border-image-source: url('../../../../assets/buttons/btn_profile_dark.svg') !important;
    //   border-image-width: 90px;
    //   border-image-slice: 28 28 28 28 fill !important; /* top | right | bottom | left | middle */

    //   height: 58px;
    // }

    // &.light {
    //   border-image-source: url('../../../../assets/buttons/btn_profile_light.svg') !important;
    //   border-image-width: 90px;
    //   border-image-slice: 28 28 28 28 fill !important; /* top | right | bottom | left | middle */

    //   height: 58px;
    // }

    // &.underline {
    //   font-family: PPPangramSansRounded-Medium;
    //   text-decoration: underline;
    //   opacity: 0.8;
    //   height: 36px;
    // }

    // width: 100%;
    // height: 56px;

    // display: flex;
    // flex-direction: row;
    // align-items: center;
    // justify-content: center;
    // gap: 6px;

    // font-family: PPPangramSansRounded-Bold;
    // font-size: 16px;

    // .gameover-button-icon {
    //   height: 16px;
    //   margin-top: -2px;
    //   img {
    //     height: 100%;
    //   }
    // }
    // }
  }
}

// =================

// .drawer-ftue-share-loading-container {
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   justify-content: center;
//   gap: 16px;

//   width: calc(100% - 32px);

//   .drawer-ftue-share-loading-icon {
//     height: 40px;
//     margin-bottom: 16px;
//     img {
//       height: 100%;
//     }
//   }

//   .drawer-ftue-share-loading {
//     font-family: PPPangramSansRounded-SemiBold;
//     font-size: 14px;
//     color: white;
//     line-height: 1.2;
//     opacity: 0.5;
//     text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
//   }

//   .progress-bar {
//     position: relative;
//     width: 100%;
//     height: 16px;
//     border-radius: 100px;

//     .progress-bar-bg {
//       position: absolute;
//       width: 100%;
//       height: 100%;
//       border-radius: 100px;
//       background-color: rgba(255, 255, 255, 0.5);
//     }

//     .progress-bar-value {
//       position: absolute;
//       width: 50%;
//       height: 100%;
//       border-radius: 100px;
//       background-color: rgba(255, 255, 255, 1);

//       transition: all 150ms linear;
//     }
//   }
// }

// .points-container {
//   padding-top: 8px;

//   .points-value {
//     font-family: PPPangramSansRounded-Bold;
//     font-size: 48px;
//     text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
//   }

//   .points-label-container {
//     display: flex;
//     flex-direction: row;
//     align-items: center;
//     justify-content: center;
//     gap: 8px;

//     height: 24px;

//     .points-icon {
//       height: 18px;
//       img {
//         height: 100%;
//       }
//     }

//     .points-label {
//       pointer-events: none;
//       font-family: PPPangramSansRounded-Bold;
//       font-size: 14px;
//       display: block;
//       color: #dedede;
//       text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
//       margin-top: 2px;
//     }
//   }
// }

// .drawer-tiktok-holders-header-close {
//   width: 32px;
//   height: 32px;
//   img {
//     width: 32px;
//     height: 32px;
//   }
// }

.page.modal-team-list {
  background-color: black;
}

.modal-header.jointeam {
  display: flex;
  flex-direction: column;
  align-items: center;

  padding-top: 16px;

  .modal-header-title {
    font-family: PPPangramSansRounded-Semibold;
    font-size: 32px;
    padding-top: 0;
    margin-top: -24px;
    z-index: 1;
  }

  .modal-header-subtitle {
    font-size: 16px;
    color: #777;
    text-align: center;
    line-height: 24px;
    padding-top: 4px;
  }
}

.btn.btn-create-team {
  position: absolute;
  left: 0;
  bottom: 50px;
  background-color: red;
  padding: 0 24px;
}

// ============================================
// Team Card

.team-list {
  width: 100%;
  height: fit-content;
  padding: 0 0 24px;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;

  .card.team {
    .card-left {
      .image {
        width: 48px;
        height: 48px;
        img {
          border-radius: 100%;
          width: 48px;
          height: 48px;
        }
      }

      .info {
        width: calc(100svw - 190px);
      }
    }
  }
}

// ============================================
// Create Team Details

.createTeam-details-header {
  .createTeam-details-title {
    font-family: PPPangramSansRounded-SemiBold;
    font-size: 32px;
    margin-top: -28px;
  }

  .createTeam-details-subtitle {
    font-family: PPPangramSansRounded-Medium;
    font-size: 14px;
    line-height: 24px;
    padding-top: 12px;
    color: #777;
  }
}

.createTeam-details-body {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 8px;

  padding: 32px 0;

  .createTeam-details-body-value-icon {
    img {
      width: 24px;
      height: 24px;
    }
  }

  .createTeam-details-body-value-label {
    font-family: PPPangramSansRounded-SemiBold;
    font-size: 28px;
  }
  .createTeam-details-body-perteam {
    font-family: PPPangramSansRounded-SemiBold;
    font-size: 16px;

    color: #777;
  }
}

.team-search-bar {
  position: relative;
  form {
    .team-search-bar-input {
      border-radius: 20px;
      font-family: PPPangramSansRounded-Medium;
      width: 100%;
      height: 40px;
      margin: 0;
      padding: 0 30px;
      background-color: #171717;
      color: #fff;
      border: 0.5px solid #999;
      box-sizing: border-box;
    }

    .icon-search {
      position: absolute;
      right: 20px;
      top: 10px;
      width: 20px;
      height: 20px;
      img {
        width: 20px;
        height: 20px;
      }
    }
  }
}

// ===================================================================
// Clicker Footer (energy bar and buttons)

.tiktok-footer {
  z-index: 9997;
  position: fixed;
  bottom: 0;

  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: start;
  justify-content: center;
  gap: 3svh;

  box-sizing: border-box;

  background-color: transparent;
}

.tiktok-footer-buttons {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-around;
  gap: 8px;

  // border-image-source: url('../../../../assets/buttons/btn_tiktok_footer.svg') !important;
  // border-image-width: 90px;
  // border-image-slice: 28 28 28 28 fill !important; /* top | right | bottom | left | middle */

  background: #000;
  border-top: 1px solid #262626;
  box-sizing: border-box;

  padding: 16px 18px;
  width: 100%;

  padding-top: 15px; // note: border-top adds 1px even with box-sizing

  &.telegram-ios {
    padding-bottom: 28px; // note: add extra bottom padding in mobile
  }

  .tiktok-footer-buttons-centered-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: space-around;
    gap: 8px;
  }
}

.tiktok-footer-button {
  position: relative;

  width: 33%;
  height: 40px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;

  overflow: hidden;

  .img {
    background-color: red;
  }

  .tiktok-footer-button-label {
    font-family: PPPangramSansRounded-Medium;
    font-size: 10px;
    color: white;

    // make sure layout doesnt break with long localization strings
    text-overflow: ellipsis;
    width: calc(100svw / 5 - 24px);
    text-wrap: nowrap;
  }

  &.create {
    overflow: unset;
    padding-bottom: 2px; // lift create button a bit, for fixing human mind tricks
  }

  &.disabled {
    pointer-events: none;
    opacity: 0.25 !important;
  }
}

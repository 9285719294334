.trading-search-bar {
  position: relative;
  opacity: 1;
  width: 100%;

  form {
    .trading-search-bar-input {
      border-radius: 20px;
      font-family: PPPangramSansRounded-Medium;
      width: 100%;
      height: 40px;
      margin: 0;
      padding: 0 20px;

      box-sizing: border-box;

      background-color: transparent;
      color: #fff;
      border: 0.5px solid #999;
      box-sizing: border-box;
    }

    .icon-search {
      position: absolute;
      right: 20px;
      top: 10px;
      width: 20px;
      height: 20px;
      img {
        width: 20px;
        height: 20px;
      }
    }
  }
}

.ban-screen {
  pointer-events: none;
  position: absolute;
  z-index: 999;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  background-color: #000;

  .loading-label {
    color: #777;
  }
}

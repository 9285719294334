.gemz-coin-item {
  .trading-token-item-profile-container {
    display: flex;
    align-items: center;
  }

  .trading-token-item-profile-image {
    width: 40px;
    height: 40px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 50%;
    }
  }

  .trading-token-item-profile-content {
    display: flex;
    justify-content: space-between;
    flex-grow: 1;
  }

  .left,
  .right {
    display: flex;
    flex-direction: column;
  }

  .coinname {
    font-family: PPPangramSansRounded-Bold;
    font-size: 16px;
    color: white;
    line-height: 1.2;
    width: 100% !important;
    text-align: left !important;
  }

  .price-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .coin {
      width: 16px;
      height: 16px;
      margin-right: 4px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .value {
      font-family: PPPangramSansRounded-Bold;
      font-size: 16px;
      color: white;
    }
  }
}

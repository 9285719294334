.drawer-earncheck-header {
  padding-bottom: 32px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .drawer-earncheck-icon {
    width: 100px;
    height: 100px;
    img {
      width: 100px;
      height: 100px;
    }
  }

  .drawer-earncheck-title {
    font-family: PPPangramSansRounded-SemiBold;
    font-size: 32px;
    padding-top: 20px;
  }

  .drawer-earncheck-subtitle {
    font-family: PPPangramSansRounded-Medium;
    font-size: 14px;
    line-height: 24px;
    padding-top: 12px;
    color: #777;
  }

  .drawer-earncheck-description {
    font-family: PPPangramSansRounded-Medium;
    font-size: 14px;
    line-height: 17px;
    padding: 5px 20px 0;
    opacity: 0.8;
  }

  .score {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;

    padding-top: 8px;
    padding-bottom: 8px;

    .text {
      font-size: 32px;
      padding-top: 2px;
    }

    .icon {
      height: 30px;
      margin-left: 4px;
      img {
        height: 100%;
      }
    }
  }
}

.drawer-earncheck {
  &.with-two-buttons {
    .subscribe-button {
      margin: 15px 0;
      width: 60%;
    }

    .drawer-earncheck-header {
      padding-bottom: 15px;
    }

    .drawer-earncheck-title {
      padding-top: 15px;
    }
  }
}

// ========================================================================================
// Booster Details

.booster-details-layout {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 12px;

  .booster-details-header {
    position: relative;
    .booster-details-icon {
      img {
        width: 100px;
        height: 100px;
      }
    }

    .booster-details-title {
      font-family: PPPangramSansRounded-SemiBold;
      font-size: 40px;
      padding-top: 24px;
    }

    .booster-details-subtitle {
      font-family: PPPangramSansRounded-SemiBold;
      font-size: 18px;
      color: #666;

      padding: 16px 12px;
      line-height: 28px;
    }
  }

  .booster-details-body {
    padding-bottom: 28px;

    .booster-details-effect {
      font-family: PPPangramSansRounded-SemiBold;
      font-size: 18px;
      line-height: 28px;
      color: #666;
      padding: 0 24px;
    }

    .booster-details-points {
      position: relative;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 8px;

      padding-top: 24px;

      .booster-details-points-icon {
        width: 24px;
        height: 24px;
        img {
          width: 24px;
          height: 24px;
        }
      }

      .booster-details-points-value {
        font-family: PPPangramSansRounded-SemiBold;
        font-size: 28px;
        text-align: left;
        padding-top: 2px;
      }
    }
  }
}

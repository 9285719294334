.overlay-layout {
  padding: 32px;

  .overlay-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 72px;

    // ===============================
    // checkmarks

    .overlay-checkmarks-area {
      .overlay-checkmarks-title {
        font-family: PPPangramSansRounded-SemiBold;
        font-size: 18px;
        padding-bottom: 8px;
      }

      .overlay-checkmarks-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 6px;

        .checkmark {
          width: 32px;
          height: 32px;

          opacity: 0.2;
          &.active {
            opacity: 1;
          }
        }
      }
    }

    // ===============================
    // image

    .modal-header-icon {
      padding-top: 8px !important;
    }

    // ===============================
    // title and subtitle

    .overlay-header-title {
      line-height: 40px;
      padding-top: unset !important;
      padding-bottom: unset !important;
    }

    .overlay-header-subtitle {
      font-size: 20px !important;
      line-height: 40px;
      color: #fff !important;

      padding-top: 8px !important;
      padding-bottom: unset !important;
    }

    // ===============================
    // end
  }
}

.modal-drawer.drawerTradingCreateConfirm {
  .modal-drawer-box {
    width: 100%;
    height: 100%;
    background-color: black;

    padding: 0;
    padding-bottom: 24px;

    border: none;
    border-radius: 0;

    overflow-y: scroll;

    .trading-create-confirm {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;

      width: 100%;
      height: 100%;
    }
  }

  .drawer-layout {
    position: relative;

    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 4px;

    .drawer-header {
      position: relative;

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-evenly;

      height: calc(100svh - 255px);

      // use linear gradient since we cannot use performance intensive blurs here
      background: linear-gradient(
        180deg,
        // rgba(255, 255, 255, 0) 0.33%
        #ffffff33 8.85%,
        rgba(255, 255, 255, 0) 97.33%
      );

      // offchainToken frame and image

      .trading-token-container {
        z-index: 1;
        position: relative;
        padding: 16px;
        padding-bottom: 8px;

        .trading-token-frame {
          position: relative;
          z-index: 0;

          width: calc(19svh + 10px);
          height: 32svh;

          display: flex;
          align-items: center;
          justify-content: center;

          img {
            width: 100%;
            height: 100%;
          }

          .trading-token-image {
            z-index: 2;
            position: absolute;
            width: 90%;
            height: 94%;
            border-radius: 12px;
            overflow: hidden;

            display: flex;
            align-items: center;
            justify-content: center;

            img {
              width: unset;
              height: 100%;
              object-fit: cover;
            }
          }

          .trading-ticker-name {
            position: absolute;
            width: 100%;

            top: 66%;

            font-family: PPPangramSansRounded-Semibold;
            font-size: 26px;

            background: linear-gradient(
              96.84deg,
              #ffffff 8.85%,
              rgba(255, 255, 255, 0) 97.33%
            );
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
          }
        }
      }

      // info

      .info {
        z-index: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 12px;

        padding: 0 24px;

        .title {
          font-family: PPPangramSansRounded-Semibold;
          font-size: 22px;
          line-height: 26px;
        }

        .tip {
          font-family: PPPangramSansRounded-Semibold;
          font-size: 14px;
          color: #777;
          padding-top: 8px;
        }
      }
    }

    // button

    .button-buy {
      .content-buy {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 8px;

        font-family: PPPangramSansRounded-Semibold;
        font-size: 18px;

        color: black;

        .icon {
          width: 20px;
          height: 20px;
          margin-top: -1px;
          img {
            width: 20px;
            height: 20px;
          }
        }
      }
    }
  }
}

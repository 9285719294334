.error-fallback {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  p {
    color: white;
    font-size: 14px;
  }
}

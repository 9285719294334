.drawer-dailyreward {
  .drawer-dailyreward-icon {
    margin-bottom: 10px;
  }

  .drawer-dailyreward-title {
    font-size: 30px;
  }

  .drawer-dailyreward-subtitle {
    opacity: 0.6;
    font-size: 16px;
    line-height: 20px;
    padding: 5px 30px 15px;
  }

  .rewards {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 7px;
    width: 100%;
    margin-bottom: 20px;
  }

  .reward-item {
    color: white;
    background: linear-gradient(180deg, #2bc44f 0%, #107b25 100%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
    padding: 6px;
    position: relative;
    font-size: 14px;
    line-height: 20px;

    .coin-bg {
      background-color: #000;
      opacity: 0.2;
      width: 36px;
      height: 36px;
      border-radius: 50%;
    }
    .coin {
      width: 30px;
      height: 30px;
      position: absolute;
    }
  }

  .reward-item::before {
    content: '';
    position: absolute;
    top: -1px;
    left: -1px;
    right: -1px;
    bottom: -1px;
    border-radius: 12px;
    padding: 2px;
    background: linear-gradient(180deg, #75fa96 0%, #128129 100%);

    mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    -webkit-mask: linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);

    mask-composite: exclude;
    -webkit-mask-composite: destination-out;

    pointer-events: none;
  }

  .reward-item.inactive {
    background: #191919;
    opacity: 0.5;
    &::before {
      display: none;
    }
  }

  .reward-item.unclaimed {
    background: none;
    &::before {
      display: block;
    }
  }

  .btn-normal {
    font-family: PPPangramSansRounded-Medium;
  }

  .btn-disabled {
    background-color: #191919;
    color: #fff;
  }
}

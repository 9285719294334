$blur: 11px;

.modal-drawer.drawerTiktokInvite {
  .modal-drawer-backdrop {
    -webkit-backdrop-filter: blur($blur) !important;
    backdrop-filter: blur($blur) !important;
    background-color: rgba(0, 0, 0, 0.25) !important;
  }
  .modal-drawer-box {
    padding-top: 16px;

    background-color: unset; // rgba(255, 0, 0, 0.5);
    position: absolute;
    top: 0;

    border: none;
    border-radius: 0;

    height: 100%;
    width: 100%;

    animation: unset;
    padding-bottom: 0 !important;
  }
}

.drawer-tiktok-invite {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  height: 100%;

  // ==========================
  .copy-success {
    position: absolute;
    // bottom: 190px;
    top: 0;

    width: 100%; // calc(100% - 48px);
    height: 50px;

    padding: 16px;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 8px;

    background-color: rgba(0, 0, 0, 0.5);

    opacity: 0;
    transition: all 250ms ease-in-out;

    &.in {
      opacity: 1;

      transform: translateY(0px);
    }

    &.out {
      opacity: 0;

      transform: translateY(-20px);
    }

    .copy-success-icon {
      width: 20px;
      height: 20px;
      img {
        width: 20px;
        height: 20px;
      }
    }

    .copy-success-text {
      font-family: PPPangramSansRounded-Medium;
      font-size: 13px;
      color: white;
      padding-top: 2px;
      box-sizing: border-box;
    }
  }

  // ==========================

  .drawer-tiktok-invite-header {
    height: 100px;
    width: 100%;

    padding-top: 9svh; // 80px;

    font-family: PPPangramSansRounded-Bold;
    font-size: 32px;
    color: white;
  }

  .drawer-tiktok-invite-content {
    height: fit-content;
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .invite-items-title {
      font-family: PPPangramSansRounded-Bold;
      font-size: 24px;
      color: white;

      width: 100%;
      padding: 5svh 0;

      color: #f5c005;
    }

    // -----------

    .invite-items {
      position: relative;

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 8px;

      margin-left: 8px;

      .invite-items-roadmap-line {
        position: absolute;
        left: 9px;
        top: 24px;
        background-color: white;
        width: 2px;
        height: 128px;
      }

      .item-invite {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: start;
        gap: 28px;

        width: 100%;

        .bullet-point {
          font-family: PPPangramSansRounded-Bold;
          font-size: 48px;
          color: white;
        }
        .item-invite-info {
          display: flex;
          flex-direction: column;
          align-items: start;
          justify-content: center;
          gap: 4px;

          .item-invite-title {
            font-family: PPPangramSansRounded-Bold;
            font-size: 16px;
            color: white;
            text-align: left;
          }
          .item-invite-subtitle {
            font-family: PPPangramSansRounded-Medium;
            font-size: 14px;
            color: white;
            text-align: left;
          }
        }
      }
    }
    // -----------

    .drawer-tiktok-invite-header-message {
      font-family: PPPangramSansRounded-SemiBold;
      font-size: 16px;
      color: white;
      line-height: 1.2;
      text-align: left;
    }

    .points-container {
      .points-value {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 8px;

        .points-value-coin {
          width: 36px;
          img {
            width: 100%;
          }
        }

        .points-value-label {
          font-family: PPPangramSansRounded-Bold;
          font-size: 48px;
          text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
        }
      }

      .points-label {
        pointer-events: none;
        font-family: PPPangramSansRounded-Bold;
        font-size: 14px;
        display: block;
        color: #dedede;
        text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
        margin-top: 2px;
      }
    }

    .drawer-tiktok-holders-header-close {
      width: 32px;
      height: 32px;
      img {
        width: 32px;
        height: 32px;
      }
    }
  }

  .drawer-tiktok-invite-buttons {
    width: 100%;
    margin-bottom: 24px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;

    .gameover-button {
      &.white {
        background-color: white;
        border: 1ps solid black;
        color: black;
        border-radius: 90px;

        border-image-source: unset;
      }

      &.dark {
        border-image-source: url('../../../../assets/buttons/btn_profile_dark.svg') !important;
        border-image-width: 90px;
        border-image-slice: 28 28 28 28 fill !important; /* top | right | bottom | left | middle */

        height: 58px;
      }

      &.light {
        border-image-source: url('../../../../assets/buttons/btn_profile_light.svg') !important;
        border-image-width: 90px;
        border-image-slice: 28 28 28 28 fill !important; /* top | right | bottom | left | middle */

        height: 58px;
      }

      &.underline {
        font-family: PPPangramSansRounded-Medium;
        text-decoration: underline;
        opacity: 0.8;
        height: 36px;
      }

      width: 100%;
      height: 56px;

      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 6px;

      font-family: PPPangramSansRounded-Bold;
      font-size: 16px;

      .gameover-button-icon {
        height: 16px;
        margin-top: -2px;
        img {
          height: 100%;
        }
      }
    }
  }
}

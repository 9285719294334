// =============================================
// chart types

.trading-chart {
  overflow-y: auto;

  position: relative;
  z-index: 0;
  width: 100%;
  height: fit-content;
}

.trading-mini-chart {
  pointer-events: none;
  overflow-y: auto;

  position: relative;
  z-index: 0;
  width: 100%;
  height: fit-content;
}

.portfolio-chart {
  overflow-y: auto;

  position: relative;
  z-index: 0;
  width: 100%;
  height: fit-content;
}

// =============================================
// Interval Buttons

.time-interval-buttons {
  display: flex;
  justify-content: space-between;
  gap: 8px;

  width: 100%;
  margin-top: 12px;
  margin-bottom: 4px;
}

.interval-button {
  cursor: pointer;
  background-color: transparent;
  border: none;
  color: white;
  font-size: 14px;

  padding: 8px 12px;
  border-radius: 8px;
  transition: background-color 0.3s ease;
}

.interval-button.active {
  background-color: #222;
  color: white;
}

// =============================================
// powerup card

.powerup-card {
  background-color: #222;
  border-radius: 12px;
  width: calc(50% - 4px);
  height: fit-content;
  display: flex;
  flex-direction: column;
  position: relative;

  // note: all badge styles are defined in Badge.scss
  // .whats-new-badge {
  //   right: -3px;
  //   top: -3px;
  // }

  &.gift-card {
    background: linear-gradient(180deg, #feca1b 0%, #000000 100%);
  }

  &.highlight {
    border: 1px solid #ffce06;
  }

  .puc-img-container {
    position: relative;
    padding: 8px;
    padding-bottom: 6px;

    display: flex;
    align-items: center;
    justify-content: center;

    .puc-img {
      position: relative;
      width: 70px;
      height: 70px;

      img {
        position: relative;
        width: 70px;
        border-radius: 6px;

        &.disabled {
          opacity: 0.4;
        }
      }

      .locked {
        position: absolute;
        top: 18px;
        left: 20px;
        width: 32px;
      }

      .timer {
        position: absolute;
        bottom: -8px;
        left: 0;

        display: flex;
        flex-direction: row;
        justify-content: center;
        gap: 4px;

        background-color: rgba(0, 0, 0, 0.5);
        width: 100%;

        padding: 4px 0;

        .icon {
          width: 15px;
        }

        .icon-tinted {
          filter: invert(26%) sepia(84%) saturate(6764%) hue-rotate(354deg)
            brightness(94%) contrast(104%);
        }

        .label {
          font-family: PPPangramSansRounded-Semibold;
          font-size: 12px;
          color: white;
          padding-top: 2px;
        }
      }

      .timer.above {
        top: 0px;
        bottom: auto;
      }
    }
    &.with-duration {
      .puc-overlay {
        display: flex;
        flex-direction: column;
        color: #f5c005;
        // position: absolute;
        // left: 25%;
        // z-index: 10;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        // Hackintosh
        margin-bottom: -13px;

        img {
          width: 50%;
          filter: brightness(70%) sepia(10) saturate(10) hue-rotate(4deg);
        }
      }
    }
  }

  .puc-name {
    font-family: PPPangramSansRounded-Semibold;
    font-size: 16px;
    color: white;
    padding-bottom: 6px;
    margin: 8px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 36px;

    div {
      width: 100%;
      word-wrap: break-word;
    }
  }

  .puc-earn {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 2px;

    .puc-earn-title {
      margin-right: 5px;

      font-family: PPPangramSansRounded-Medium;
      font-size: 14px;
      color: #999;
    }

    .puc-earn-coins {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      font-family: PPPangramSansRounded-Bold;
      font-size: 14px;
      color: #f5c005;

      img {
        margin-right: 4px;
        width: 15px;
      }
    }
  }

  .puc-footer {
    border-top: 2px solid rgb(255 255 255 / 10%);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    margin: 0 8px;
    margin-top: 12px;

    min-height: 58px;

    &.gift-card {
      color: #777;
      line-height: 20px;
    }

    .puc-btn {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      width: 100%;
      padding: 0px 8px;
      padding-top: 10px;
      padding-bottom: 10px;
    }

    .puc-btn.puc-level {
      font-family: PPPangramSansRounded-Semibold;
      font-size: 14px;
      line-height: 18px;

      border-right: 1px solid rgb(255 255 255 / 10%);

      // padding-left: 8px; // 4px 8px;

      padding-left: 0;

      &.locked {
        width: 35%;
      }
    }
    .puc-btn.puc-cost {
      font-family: PPPangramSansRounded-Bold;
      font-size: 14px;
      line-height: 18px;

      padding-right: 0;

      &.disabled {
        opacity: 0.6;
        cursor: initial;
      }

      img {
        margin-right: 4px;
        width: 15px;
      }

      &.locked {
        width: 65%;
        font-family: PPPangramSansRounded-Medium;
        line-height: 18px;
        font-size: 12px;
        color: #999;

        div {
          text-align: left;
        }
      }
    }
  }
}

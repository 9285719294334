// =================================================
// Modal Overlay

// These are full screen overlays that appear over the full screen modals by fade

.modal-overlay {
  z-index: 998;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 1);
  opacity: 0;

  --blur-amount: 32px;
  background-color: var(--navbar-color);
  -webkit-backdrop-filter: blur(var(--blur-amount));
  backdrop-filter: blur(var(--blur-amount));

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .modal-overlay-box {
    width: 100%;
    height: fit-content;

    .overlay-layout {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      gap: 12px;

      .overlay-header {
        position: relative;
        .drawer-header-icon {
          img {
            width: 100px;
            height: 100px;
          }
        }

        .overlay-header-title {
          font-family: PPPangramSansRounded-SemiBold;
          font-size: 40px;
          padding-top: 24px;
          color: white;
        }

        .overlay-header-subtitle {
          font-family: PPPangramSansRounded-Medium;
          font-size: 18px;
          color: #666;
          padding: 16px 12px;
          line-height: 28px;
        }
      }

      .overlay-body {
        padding-bottom: 28px;
      }
    }
  }
}

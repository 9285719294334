.modal-drawer.drawerTradingSlippage {
  .modal-drawer-box {
    padding-top: 24px;

    .drawer-trading-slippage {
      width: 100%;
    }
  }
}

// ------------------------------

.drawer-trading-slippage-header {
  padding-bottom: 12px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  gap: 10px;

  width: 100%;

  .drawer-trading-slippage-icon {
    width: 48px;
    height: 48px;
  }

  .drawer-trading-slippage-title {
    width: 100%;
    font-family: PPPangramSansRounded-Bold;
    font-size: 16px;
    text-align: left;
  }

  .drawer-trading-slippage-form {
    width: 100%;
    height: 48px;
    background-color: #222;
    border-radius: 90px;
    padding: 8px 16px;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 4px;

    .drawer-trading-slippage-percent {
      color: #777;
    }
  }

  .drawer-trading-slippage-subtitle {
    font-family: PPPangramSansRounded-Medium;
    font-size: 14px;
    line-height: 22px;
    color: #777;
  }

  .drawer-trading-slippage-reward {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-items: center;
    gap: 8px;

    font-family: PPPangramSansRounded-SemiBold;
    font-size: 14px;

    .icon {
      background-color: #222;
      width: 20px;
      height: 20px;
      border-radius: 0px; // 6px;
    }
    .value {
      color: white;
    }
    .currency {
      color: #f5c005;
    }
  }
}

.modal-drawer.drawerS1 {
  .modal-drawer-box {
    padding-top: 24px;

    .drawer-s1 {
      width: 100%;
    }
  }
}

// ------------------------------

.drawer-s1-header {
  padding-bottom: 12px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  gap: 10px;

  width: 100%;

  .drawer-s1-icon {
    background-image: url('../../../../assets/mockups/trading/trading_coins_500k.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;

    width: 144px;
    height: 144px;

    margin-top: 12px;
  }

  .drawer-s1-title {
    .drawer-s1-title-label {
      width: 100%;
      font-family: PPPangramSansRounded-SemiBold;
      font-size: 16px;
      color: #777;

      margin-bottom: 4px;
    }

    .drawer-s1-title-coins {
      display: flex;
      flex-direction: row;
      gap: 8px;
      align-items: center;
      justify-content: center;

      .label {
        font-family: PPPangramSansRounded-SemiBold;
        font-size: 30px;
        margin-top: 3px;
      }

      .icon {
        height: 26px;
        img {
          height: 100%;
        }
      }
    }
  }

  .drawer-s1-rows {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 12px;

    margin-top: 16px;

    .drawer-s1-row {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      width: 100%;

      .drawer-s1-row-title {
        font-family: PPPangramSansRounded-Medium;
        font-size: 16px;
        color: #777;
      }

      .drawer-s1-row-coins {
        display: flex;
        flex-direction: row;
        gap: 4px;
        align-items: center;
        justify-content: center;

        .label {
          font-family: PPPangramSansRounded-SemiBold;
          font-size: 16px;
          margin-top: 3px;
        }

        .icon {
          height: 20px;
          img {
            height: 100%;
          }
        }
      }
    }
  }

  .drawer-s1-footer {
    width: 100%;
    font-family: PPPangramSansRounded-Medium;
    font-size: 14px;
    color: #777;
    margin-top: 16px;
  }
}

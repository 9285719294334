// =============================================
// trading offchainToken item

.trading-token-item-holder {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;

  padding: 16px 0;
  padding-bottom: 16px;
  border-bottom: 1px rgba(255, 255, 255, 0.3) solid;

  .trading-token-item-holder-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    gap: 12px;
    width: 100%;
    height: 48px;

    padding-top: 8px;

    .trading-token-item-holder-image {
      width: 48px;
      height: 48px;
      border-radius: 12px;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }

    .trading-token-item-holder-content {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      width: calc(100% - 48px);

      .left {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 4px;

        .name-container {
          display: flex;
          flex-direction: row;
          justify-content: start;
          gap: 4px;

          font-family: PPPangramSansRounded-SemiBold;
          font-size: 14px;

          .tickername {
            color: white;
          }
          .tokenname {
            color: #777;
          }
        }

        .owned-container {
          display: flex;
          flex-direction: row;
          justify-content: start;
          gap: 4px;

          font-family: PPPangramSansRounded-SemiBold;
          font-size: 14px;

          .label {
            color: #777;
          }
          .value {
            color: white;
          }
        }
      }

      .right {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 4px;

        .price-container {
          display: flex;
          flex-direction: row;
          gap: 4px;
          align-items: center;
          justify-content: end;

          .label {
            font-family: PPPangramSansRounded-SemiBold;
            font-size: 14px;
            margin-top: 3px;
          }

          .coin {
            height: 14px;
            img {
              height: 100%;
            }
          }
        }

        .percent-container {
          display: flex;
          flex-direction: row;
          gap: 4px;
          align-items: start;
          justify-content: end;

          .arrow {
            width: 10px;
            height: 8px;

            img {
              width: 100%;
            }

            &.green {
              margin-top: -3px;
            }
            &.red {
              margin-top: -2px;
            }
          }

          .percent {
            font-family: PPPangramSansRounded-Bold;
            font-size: 12px;
            color: white;
            &.green {
              color: #09d0a8;
            }
            &.red {
              color: #d00948;
            }
          }
        }
      }
    }
  }
}

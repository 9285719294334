// =================================================
// Farming UI

.farming-footer-button {
  // ensure the farming UI is clickable
  pointer-events: auto;
  cursor: pointer;

  position: relative;

  border-image-source: url('../../../../assets/buttons/btn_profile_dark.svg');
  border-image-width: 90px;
  border-image-slice: 28 28 28 28 fill;

  width: calc(100% - 20px);
  height: 56px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start !important;
  gap: 6px;

  padding: 8px;

  .farming-icon {
    position: relative;
    width: 42px;
    height: 42px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 90px;
    }

    .farming-points {
      z-index: 1;
      font-family: PPPangramSansRounded-Bold;
      font-size: 11px;
      color: white;
      width: 100%;

      text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    }
  }

  .farming-labels {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    gap: 4px;
  }

  .farming-label {
    font-family: PPPangramSansRounded-Bold;
    font-size: 12px;
    color: black;
  }

  .farming-tappable {
    position: absolute;
    top: 2px;
    left: 2px;
    width: calc(100% - 4px);
    height: calc(100% - 4px);
    padding: 0px;
    background-color: white;
    border-radius: 90px;
  }

  // NONE

  &.none {
    justify-content: center;

    .farming-label {
      z-index: 1;
    }

    .farming-icon {
      position: relative;
      width: 34px;
      height: 34px;
      margin-top: -2px;
      margin-left: 2px;
    }
  }

  // FARMING

  &.farming {
    .farming-label {
      color: white;
      opacity: 0.5;
      padding-left: 2px;
    }

    .farming-timer {
      font-family: PPPangramSansRounded-SemiBold;
      font-size: 11px;
      color: white;
      // opacity: 0.5;
      padding-left: 2px;
    }
  }

  // FINISHED (CLAIM)

  &.finished {
    .farming-label {
      z-index: 1;
    }
  }
}

// =================================================

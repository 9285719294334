/*
  Carles I think we should put any shared classes here; since modal includes drawer;popup;overaly;(possibly toast);
  we should not make them specific, so I'd suggest they are prefixed with modal- (and not include drawer, popup, etc)
  Then import this base scss in each specific scss (exmaple in popup) so the component always import the specific file
*/
.modal-button-close {
  pointer-events: auto;
  cursor: pointer;
  position: absolute;
  right: 16px;
  top: 16px;
}

.roi-box {
  width: 100%;
  height: 70px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 6px;

  padding-top: 2px;
}

.roi-box-coins {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 6px;

  .coin {
    width: 24px;
    height: 24px;
    img {
      width: 100%;
    }
  }

  .value {
    font-family: PPPangramSansRounded-Semibold;
    font-size: 24px;
    color: white;
    padding-top: 1px;
  }
}

.roi-box-footer {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 4px;

  .label {
    font-family: PPPangramSansRounded-Medium;
    font-size: 12px;
    color: #777;
  }

  .arrow {
    width: 10px;
    height: 8px;

    img {
      width: 100%;
    }

    &.green {
      margin-top: -14px;
      margin-right: 0px;
    }
    &.red {
      margin-top: -11px;
      margin-right: -1px;
    }
  }
  .percent {
    font-family: PPPangramSansRounded-Bold;
    font-size: 12px;
    color: white;
    &.green {
      color: #09d0a8;
    }
    &.red {
      color: #d00948;
    }
  }
}

// ==========================================================
// header

.modal-league {
  hr {
    width: 100%;
    height: 0.5;
    opacity: 0.5;
  }
}

.modal-header.leaguepage {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  padding-top: 16px;

  .header-nav {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    // .glowing-gem {
    //   position: relative;

    //   .gem-dust {
    //     opacity: 0.3;
    //     position: absolute;
    //     top: 4px;
    //     left: 0;
    //     width: 160px;
    //     height: 160px;
    //     img {
    //       width: 160px;
    //       height: 160px;
    //     }
    //   }
    // }

    .btn-left {
      transform: rotate(180deg);
      width: 40px;
      height: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      .img {
        width: 32%;
      }
    }

    .btn-right {
      width: 40px;
      height: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      .img {
        width: 32%;
      }
    }
  }

  .modal-header-glow {
    z-index: -1;
    pointer-events: none;
    position: absolute;
    // top: -160px;
    // transform: scale(0.75);
  }

  // .modal-header-icon img {
  //   width: 100px;
  //   height: 100px;
  //   border-radius: 100%;
  // }

  .modal-header-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 8px;

    padding-top: 20px;

    .modal-header-title-name {
      font-family: PPPangramSansRounded-Semibold;
      font-size: 32px;
    }

    .modal-header-title-share {
      filter: invert(0.75);
      width: 32px;
      height: 32px;
      img {
        width: 32px;
        height: 32px;
      }
    }
  }

  .modal-header-subtitle {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 4px;

    padding-top: 8px;

    .mhst {
      margin-top: 12px;
    }

    .progress-label {
      margin-top: 12px;

      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 8px;

      .progress-label-icon {
        width: 28px;
        height: 28px;
        img {
          width: 28px;
          height: 28px;
        }
      }
      .progress-label-text {
        font-family: PPPangramSansRounded-Medium;
        font-size: 16px;
        padding-top: 6px;
      }
    }

    .progress-bar {
      margin-top: 16px;
      width: calc(100svw - 48px);
    }

    .rank {
      width: 24px;
      height: 24px;
      img {
        width: 24px;
        height: 24px;
      }
    }

    .modal-header-subtitle-rank {
      font-family: PPPangramSansRounded-Semibold;
      font-size: 14px;
      color: #777;
    }
  }
}

.team-list.leaguepage {
  gap: 4px;
  padding-top: 0;

  .card.team-member {
    padding: 0 5px;
    &.current-user-card {
      border: 1px solid #333;
      border-radius: 6px;
      background: black;
    }
    .card-left {
      .image {
        width: 32px;
        height: 32px;
        img {
          width: 32px;
          height: 32px;
        }
      }
    }
  }

  &.linked-user-card {
    .out-rank {
      position: fixed;
      bottom: 5px;
    }
  }
}

.progress-bar {
  position: relative;
  width: 200%;
  height: 12px;
  border-radius: 100px;

  .progress-bar-bg {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 100px;
    background-color: rgba(255, 255, 255, 0.5);
  }

  .progress-bar-value {
    position: absolute;
    width: 50%;
    height: 100%;
    border-radius: 100px;
    background-color: rgba(255, 255, 255, 1);
  }
}

.animated-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 100000;

  // make sure elements inside animated are within bounds
  .anim-content-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
  }
}

// ton wallet popup
#tc-widget-root {
  z-index: 100001;
}

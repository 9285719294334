.sorting-header-container {
  .sorting-header {
    width: 100%;
    margin-top: 8px; // 12px;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 0px;

    .total-assets {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: start;
      gap: 6px;

      font-family: PPPangramSansRounded-SemiBold;
      font-size: 12px;

      .label {
        color: #777;
      }
      .value {
        color: white;
      }
    }

    .sort-by {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: end;
      gap: 4px;

      font-family: PPPangramSansRounded-SemiBold;
      font-size: 12px;

      .label {
        color: #777;
      }
      .value {
        color: white;
      }
    }
  }

  .separator {
    width: 100%;
    height: 0;
    border-bottom: 1px rgba(255, 255, 255, 0.3) solid;
    padding-bottom: 12px;
  }
}

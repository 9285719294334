.modal-drawer.drawerTiktokDetails {
  .modal-drawer-box {
    padding-top: 16px;
  }
}

.drawer-tiktok-details {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: space-between;
  gap: 12px;

  .drawer-tiktok-details-header {
    width: 100%;
    height: 40px;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .drawer-tiktok-details-header-title {
      font-family: PPPangramSansRounded-SemiBold;
      font-size: 16px;
    }

    .drawer-tiktok-details-header-close {
      width: 32px;
      height: 32px;
      img {
        width: 32px;
        height: 32px;
      }
    }
  }

  // this overrides normal TradingTokenTransactionItem layout
  .items-area {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 24px;
    padding-bottom: 24px;

    .items-list {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 8px;

      width: 100%;
      height: fit-content;
      border-radius: 12px;
    }
  }

  .header-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    width: 100%;

    // ---------------------
    .item {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: start;
      gap: 12px;
      flex-shrink: 0;

      padding-bottom: 8px;

      // todo: image looks stretched - fix it
      .image {
        width: 56px;
        height: 56px;
        overflow: hidden;
        border-radius: 90px;

        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: start;
        gap: 8px;

        img {
          width: 100%;
        }
      }

      .info {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: start;
        gap: 4px;

        .item-name {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: start;
          gap: 8px;
          flex-shrink: 0;

          .tickerName {
            font-family: PPPangramSansRounded-SemiBold;
            font-size: 16px;

            /* gradientz */
            background: linear-gradient(
              305.83deg,
              #ffee10 27.33%,
              #ffb900 86.11%
            );
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
          }

          .name {
            font-family: PPPangramSansRounded-SemiBold;
            font-size: 13px;
            color: white;
          }
        }

        // ---------------------
        .value-area {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: start;
          gap: 12px;
          flex-shrink: 0;

          width: 100%;

          .price-container {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: end;
            gap: 6px;

            .label {
              font-family: PPPangramSansRounded-Bold;
              font-size: 18px;
              margin-top: 3px;
            }

            .coin {
              height: 20px;
              width: 20px;
              img {
                height: 100%;
              }
            }
          }

          .percent-container {
            display: flex;
            flex-direction: row;
            gap: 4px;
            align-items: start;
            justify-content: end;

            margin-top: 4px;

            .arrow {
              width: 10px;
              height: 8px;

              img {
                width: 100%;
              }

              &.green {
                margin-top: -3.5px;
              }
              &.red {
                margin-top: -3.5px;
              }
            }

            .percent {
              font-family: PPPangramSansRounded-Bold;
              font-size: 12px;
              color: white;
              &.green {
                color: #09d0a8;
              }
              &.red {
                color: #d00948;
              }
            }
          }
        }
        // ---------------------
      }
    }

    .coming-soon {
      .image {
        width: 56px;
        height: 56px;
        img {
          height: 100%;
        }
      }
    }
  }
}

// =============================================
// drawer details header boxes

.drawer-details-boxes {
  width: 100%;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 6px;

  margin-bottom: 4px;

  .drawer-details-box {
    width: 100%;
    height: 70px; // todo carles

    padding: 12px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0px;

    border: 1px solid #444;
    border-radius: 12px;

    .value-area {
      display: flex;
      flex-direction: column;
      align-items: center; // end;
      justify-content: center;
      gap: 4px;

      .title {
        font-family: PPPangramSansRounded-SemiBold;
        font-size: 11px;
        color: #999;
      }

      .value {
        font-family: PPPangramSansRounded-SemiBold;
        font-size: 18px;
        color: white;
        margin-top: 1px;
      }

      .drawer-details-box-percent {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 4px;

        .label {
          font-family: PPPangramSansRounded-Medium;
          font-size: 12px;
          color: #777;
        }

        .arrow {
          width: 10px;
          height: 8px;

          img {
            width: 100%;
          }

          &.green {
            margin-top: -14px;
            margin-right: 0px;
          }
          &.red {
            margin-top: -14px;
            margin-right: -1px;
          }
        }
        .percent {
          font-family: PPPangramSansRounded-Bold;
          font-size: 12px;
          color: white;
          &.green {
            color: #09d0a8; // green
          }
          &.red {
            // color: #d00948; // red
            color: #ef454a; // red bright
          }
        }
      }
    }
  }
}

// =============================================
// About - content is set on TradingTabsContent

.about {
  margin-bottom: 2px;
}

.modal-drawer.drawerTiktokHolders {
  .modal-drawer-box {
    padding-top: 16px;
  }
}

.drawer-tiktok-holders {
  .drawer-tiktok-holders-header {
    width: 100%;
    height: 40px;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .drawer-tiktok-holders-header-title {
      font-family: PPPangramSansRounded-SemiBold;
      font-size: 16px;
    }

    .drawer-tiktok-holders-header-close {
      width: 32px;
      height: 32px;
      img {
        width: 32px;
        height: 32px;
      }
    }
  }

  // this overrides normal TradingTokenTransactionItem layout
  .items-area {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 16px;
    padding-bottom: 24px;

    max-height: calc(100svh - 200px);
    overflow-y: scroll;

    .items-list {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 8px;

      width: 100%;
      height: fit-content;
      border-radius: 12px;
    }
  }
}

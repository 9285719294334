// .modal .modal-page.earn {
//   gap: 12px;
// }

#earn {
  .page.earn {
    // background-color: red;
    padding-bottom: 120px;
  }
}

.modal-header.earn {
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 16px 0;
  padding-top: 24px;

  .modal-header-glow {
    pointer-events: none;
    position: absolute;
    top: -160px;
    transform: scale(0.75);
    z-index: -1;
  }

  .modal-header-title {
    z-index: 1;
  }

  .modal-header-icon {
    position: relative;
  }

  .modal-header-title {
    font-family: PPPangramSansRounded-Semibold;
    font-size: 32px;
    padding-top: 0; // 8px;
    margin-top: -24px;
  }
}

.earn-section-title {
  font-family: PPPangramSansRounded-Semibold;
  font-size: 16px;
  text-align: left;

  width: 100%;
  padding-top: 12px;
}

// ========================================================================================
// Earn Card

.earn-card {
  background-color: #222;
  border-radius: 12px;
  padding: 16px;
  width: 100%;
  height: 82px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 16px;

  cursor: pointer;

  &.disabled {
    opacity: 0.6;
    cursor: initial;
  }

  &.promo {
    outline: 2px solid #fdec3a;
    outline-offset: 0px;

    position: relative;
    box-sizing: border-box;
  }

  .promo-image {
    position: absolute;
    top: -2px;
    left: -2px;
    width: 61px;
    height: 47px;
    z-index: 1;
  }

  .earn-card-icon {
    width: 36px;
    height: 36px;
    img {
      width: 36px;
      height: 36px;
    }
  }

  .earn-card-info {
    width: 73%; // 80%;

    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    gap: 4px;

    .earn-card-info-title {
      font-family: PPPangramSansRounded-Bold;
      font-size: 16px;
      text-align: left;
      color: #ffb900;
    }

    .earn-card-info-points {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: start;
      gap: 6px;

      .earn-card-info-points-icon {
        width: 20px;
        height: 20px;
        img {
          width: 20px;
          height: 20px;
        }
      }

      .earn-card-info-points-value {
        font-family: PPPangramSansRounded-Medium;
        font-size: 16px;
        text-align: left;
        padding-top: 3px;
      }

      .earn-card-info-subtitle {
        font-family: PPPangramSansRounded-Medium;
        font-size: 16px;
        text-align: left;
      }
    }
  }

  // .earn-card-arrow {
  // }
}

.tooltip {
  // pointer-events: auto;
  z-index: 9998;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  .tooltip-hand {
    position: absolute;
    height: 32px;
    width: 32px;
    top: 100%;
    right: calc(50% - 16px);

    img {
      height: 32px;
      width: 32px;
    }
  }

  .tooltip-box {
    border: 1px solid white;
    width: fit-content;
    padding: 8px 16px;
    border-radius: 24px;
    min-height: initial;

    display: flex;
    flex-direction: column;
    position: relative;
    align-items: center;
    justify-content: space-around;

    background-color: rgba(0, 0, 0, 0.8);
  }
}

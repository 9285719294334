.button-back {
  z-index: 997;

  position: absolute;
  top: 18px;
  left: 16px;
  width: 48px;
  height: 48px;

  img {
    width: 32px;
    height: 32px;
  }
}

.modal-header.mine {
  padding-top: 16px;
}
// =============================================
// mine points

.mine-points {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 12px;

  padding-top: 16px;
  width: 100%;

  .mine-points-icon {
    width: 40px;
    height: 40px;
  }
  .mine-points-label {
    font-family: PPPangramSansRounded-Semibold;
    font-size: 42px;
    padding-top: 6px;
  }
}

// =============================================
// earn per hour

.earn-per-hour {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 4px;

  padding-top: 4px;
  box-sizing: border-box;

  font-family: PPPangramSansRounded-Semibold;
  font-size: 14px;

  img {
    width: 16px;
    height: 16px;
  }

  .yellow {
    color: #f5c005;
  }
}

.drawer-outofenergy-header {
  padding-bottom: 32px;

  .drawer-outofenergy-title {
    font-family: PPPangramSansRounded-SemiBold;
    font-size: 32px;
  }

  .drawer-outofenergy-subtitle {
    font-family: PPPangramSansRounded-Medium;
    font-size: 14px;
    line-height: 24px;
    padding-top: 12px;
    color: #777;
  }
}

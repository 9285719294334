.team-button {
  z-index: 1;
  height: 100%;

  width: 68svw; // normal phones
  &.full-width {
    width: 100%;

    #my-team {
      justify-content: initial;
    }
  }

  // small phones
  @media screen and (max-width: 414px) {
    width: 64.5svw;

    &.full-width {
      width: 100%;
    }
  }

  &.highlight {
    color: #ffce06;
  }

  #join-team {
    height: 100%;
    font-family: PPPangramSansRounded-Bold;
    font-size: 28px;
    line-height: 24px;

    .join-team-loading {
      font-family: PPPangramSansRounded-Medium;
      font-size: 16px;
      opacity: 0.5;
      padding-top: 2px;
    }
  }

  #my-team {
    height: 100%;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 12px;

    // .img {}

    .text {
      position: relative;
      min-width: calc(100% - 150px);

      .name-label {
        font-size: 16px;
        text-align: left;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 100%;
      }

      .score {
        display: flex;
        align-items: center;
        margin-top: 4px;

        img {
          width: 20px;
          height: 20px;
          margin-right: 8px;
        }

        .points {
          margin-top: 3px;
          font-family: PPPangramSansRounded-Medium;
          font-size: 14px;
        }
      }
    }

    .rank {
      width: fit-content;
      flex: 0.3;
      .rank-label {
        width: fit-content;
      }
    }

    img {
      width: 48px;
      height: 48px;
      border-radius: 100%;
    }
  }
}

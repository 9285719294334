.token-loading {
  display: flex;
  border-radius: 10px; /* Rounded corners */
  align-items: center;
  gap: 5px;
  width: fit-content;
}

.token-image-loading {
  flex-shrink: 0;
  width: 96px;
  height: 96px;
  background-color: #343434;
  border-radius: 10px;
  position: relative;
  overflow: hidden;
}

.token-image-loading::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0%;
  height: 100%;
  width: 400%;
  background: linear-gradient(
    to right,
    rgba(20, 20, 20, 0.8) 0%,
    rgba(20, 20, 20, 0.8) 40%,
    transparent 41%,
    transparent 59%,
    rgba(20, 20, 20, 0.8) 60%,
    rgba(20, 20, 20, 0.8) 100%
  );
  animation: loading 1.7s infinite;
}

.info-section {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-left: 5px;
}

.loading-line {
  background-color: #343434;
  height: 20px;
  width: 100%;
  border-radius: 3px;
  position: relative;
  overflow: hidden;
}

.loading-line::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0%;
  height: 100%;
  width: 400%;
  background: linear-gradient(
    to right,
    rgba(20, 20, 20, 0.8) 0%,
    rgba(20, 20, 20, 0.8) 40%,
    transparent 41%,
    transparent 59%,
    rgba(20, 20, 20, 0.8) 60%,
    rgba(20, 20, 20, 0.8) 100%
  );
  animation: loading 1.7s infinite;
}

.title-placeholder {
  height: 20px;
  width: 120px;
}

.author-placeholder {
  display: flex;
  align-items: center;
  margin-top: -2px;
  gap: 5px;
  width: 90px;
  height: 16px;
}

.stats-placeholder {
  display: flex;
  flex-direction: column;
  margin-top: 4px;
  gap: 5px;
}

.stat-item-placeholder {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 16px;
  width: 135px;
}

@keyframes loading {
  0% {
    left: -300%;
  }
  100% {
    left: 0%;
  }
}

// ===================================================================
// Clicker Footer (energy bar and buttons)

.tiktok-item-transaction-bubbles {
  width: fit-content;
}

.tiktok-item-transaction-bubbles-buttons {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: end;
  gap: 0;
}

.tiktok-item-transaction-bubble-button {
  position: relative;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 8px;

  padding: 4px;

  .label-index {
    font-family: PPPangramSansRounded-Medium;
    font-size: 12px;
  }

  .tiktok-item-transaction-bubble-box {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;

    .icon {
      width: 24px;
      height: 24px;
      img {
        height: 100%;
        border-radius: 90px;
      }
    }

    .info {
      display: flex;
      flex-direction: column;
      align-items: start;
      gap: 0;

      .label {
        font-family: PPPangramSansRounded-Bold;
        font-size: 12px;

        &.name {
          font-family: PPPangramSansRounded-SemiBold;
          opacity: 0.5;
        }

        &.coins {
          font-size: 11px;
        }

        &.type {
          font-size: 11px;
          &.buy {
            color: #09d0a8; // green
          }
          &.sell {
            color: #d00948; // red
          }
        }
      }
    }
  }
}

.trading-notification {
  position: absolute;
  top: 18px;
  // top: 56px;

  opacity: 1;
  width: 100%;
  height: 60px;
  border-radius: 90px;
  padding: 12px;
  overflow: hidden;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 8px;

  border-image-source: url('../../../../assets/buttons/btn_profile_dark.svg') !important;
  border-image-width: 90px;
  border-image-slice: 28 28 28 28 fill !important; /* top | right | bottom | left | middle */

  // background-color: rgba(0, 0, 0, 0.3);
  // border: 0.5px solid rgba(255, 255, 255, 0.3);
  // border: 0.5px solid #444;

  .left {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
    gap: 12px;

    .image {
      width: 40px;
      height: 40px;
      img {
        border-radius: 90px;
        width: 40px;
        height: 40px;
      }
    }

    .message {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: start;
      flex-wrap: wrap;
      gap: 4px;

      font-family: PPPangramSansRounded-Semibold;
      font-size: 12px;

      .user-name {
        color: white;
      }

      .just-created {
        color: #777;
      }

      .token-name {
        color: #f5c005;
      }
    }
  }

  .right {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
    gap: 6px;

    font-family: PPPangramSansRounded-Semibold;
    font-size: 12px;

    width: 60px;
    padding-right: 2px;

    .token-time {
      color: #777;
    }
  }
}

@keyframes trading-notification-slide-in {
  0% {
    animation-timing-function: cubic-bezier(0.1, 1.02, 0.84, 0.99);
    transform: translateY(-20px);
    opacity: 0;
  }

  100% {
    animation-timing-function: cubic-bezier(0.1, 1.02, 0.84, 0.99);
    opacity: 1;
    transform: translateY(0px);
  }
}

.trading-notification-slide-in {
  animation: trading-notification-slide-in 0.25s ease-in-out 0s 1 normal
    forwards;
  animation-delay: 0s; // in case we want to add a delay to the opening animation
  transform: translateY(-20px);
  visibility: visible;
  opacity: 0;
}

@keyframes trading-notification-slide-out {
  0% {
    animation-timing-function: cubic-bezier(0.66, 0.04, 0.92, 0.52);
    opacity: 1;
  }

  100% {
    animation-timing-function: cubic-bezier(0.66, 0.04, 0.92, 0.52);
    transform: translateY(-70%);
    opacity: 0;
  }
}

.trading-notification-slide-out {
  animation: trading-notification-slide-out 0.21s ease-in-out 0s 1 normal
    forwards;
}

@keyframes trading-notification-fade-in {
  0% {
    animation-timing-function: cubic-bezier(0.1, 1.02, 0.84, 0.99);
    transform: scale(1);
    opacity: 0;
  }

  10% {
    animation-timing-function: cubic-bezier(0.1, 1.02, 0.84, 0.99);
    transform: scale(1.04);
    opacity: 1;
  }

  100% {
    animation-timing-function: cubic-bezier(0.1, 1.02, 0.84, 0.99);
    transform: scale(1);
    opacity: 1;
  }
}

.trading-notification-fade-in {
  animation: trading-notification-fade-in 0.32s ease-in-out 0s 1 normal forwards;
  animation-delay: 0s; // in case we want to add a delay to the opening animation
  transform: scale(1.2);
  visibility: visible;
  opacity: 0;
}

@keyframes trading-notification-fade-out {
  0% {
    animation-timing-function: cubic-bezier(0.66, 0.04, 0.92, 0.52);
    opacity: 1;
  }

  100% {
    animation-timing-function: cubic-bezier(0.66, 0.04, 0.92, 0.52);
    opacity: 0;
  }
}

.trading-notification-fade-out {
  animation: trading-notification-fade-out 5s ease-in-out 0s 1 normal forwards;
}

.trading-notification-hidden {
  opacity: 0;
}

.modal-header.maintenance {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  height: 95%;

  // padding: 16px 0;

  .modal-header-title {
    font-family: PPPangramSansRounded-Semibold;
    font-size: 32px;
    line-height: 36px;
    padding-top: 32px;
  }

  .modal-header-subtitle {
    font-size: 16px;
    color: #777;
    text-align: center;
    line-height: 24px;
    padding-top: 24px;
    padding-bottom: 32px;
  }
}

.btn.btn-findoutmore {
  margin-top: 0px;
  max-width: 220px;
}

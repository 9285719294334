// ===================================================================

.clicker-header {
  z-index: 1;

  width: 100%;
  height: 13svh; // todo carles: less than this creates weird 9slice issues
  min-height: 64px;
  max-height: 76px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 8px;

  margin-top: 8px;
}

.button-s1 {
  background-image: url('../../../../assets/buttons/btn_clicker_s1.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;

  height: 100%;
  width: 100%;
  min-width: 64px;
  max-width: 76px;
}

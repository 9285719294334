$blur: 11px;

.modal-drawer.drawerTiktokGameOver {
  .modal-drawer-backdrop {
    -webkit-backdrop-filter: blur($blur) !important;
    backdrop-filter: blur($blur) !important;
    background-color: rgba(0, 0, 0, 0.25) !important;
  }
  .modal-drawer-box {
    padding-top: 16px;

    background-color: unset; // rgba(255, 0, 0, 0.5);
    position: absolute;
    top: 0;

    border: none;
    border-radius: 0;

    height: 100%;
    width: 100%;

    animation: unset;
    padding-bottom: 0 !important;
  }
}

.drawer-tiktok-gameover {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  height: 100%;

  .drawer-tiktok-gameover-header {
    height: 100px;
    width: 100%;
  }

  .drawer-tiktok-gameover-content {
    height: fit-content;
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    gap: 24px;

    padding-bottom: 64px;

    height: 280px;
    padding-top: 24px;

    .drawer-tiktok-gameover-header-message {
      font-family: PPPangramSansRounded-SemiBold;
      font-size: 24px;
      color: white;
      line-height: 1.2;
      text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
    }

    .drawer-tiktok-gameover-loading-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 16px;

      width: calc(100% - 32px);

      .drawer-tiktok-gameover-loading-icon {
        height: 40px;
        margin-bottom: 16px;
        img {
          height: 100%;
        }
      }

      .drawer-tiktok-gameover-loading {
        font-family: PPPangramSansRounded-SemiBold;
        font-size: 14px;
        color: white;
        line-height: 1.2;
        opacity: 0.5;
        text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
      }

      .progress-bar {
        position: relative;
        width: 100%;
        height: 16px;
        border-radius: 100px;

        .progress-bar-bg {
          position: absolute;
          width: 100%;
          height: 100%;
          border-radius: 100px;
          background-color: rgba(255, 255, 255, 0.5);
        }

        .progress-bar-value {
          position: absolute;
          width: 50%;
          height: 100%;
          border-radius: 100px;
          background-color: rgba(255, 255, 255, 1);

          transition: all 150ms linear;
        }
      }
    }

    .points-container {
      padding-top: 8px;

      .points-value {
        font-family: PPPangramSansRounded-Bold;
        font-size: 48px;
        text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
      }

      .points-label-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 8px;

        height: 24px;

        .points-icon {
          height: 18px;
          img {
            height: 100%;
          }
        }

        .points-label {
          pointer-events: none;
          font-family: PPPangramSansRounded-Bold;
          font-size: 14px;
          display: block;
          color: #dedede;
          text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
          margin-top: 2px;
        }
      }
    }

    .drawer-tiktok-holders-header-close {
      width: 32px;
      height: 32px;
      img {
        width: 32px;
        height: 32px;
      }
    }
  }

  .drawer-tiktok-gameover-buttons {
    width: 100%;
    margin-bottom: 24px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;

    .gameover-button {
      &.white {
        background-color: white;
        border: 1ps solid black;
        color: black;
        border-radius: 90px;

        border-image-source: unset;
      }

      &.dark {
        border-image-source: url('../../../../assets/buttons/btn_profile_dark.svg') !important;
        border-image-width: 90px;
        border-image-slice: 28 28 28 28 fill !important; /* top | right | bottom | left | middle */

        height: 58px;
      }

      &.light {
        border-image-source: url('../../../../assets/buttons/btn_profile_light.svg') !important;
        border-image-width: 90px;
        border-image-slice: 28 28 28 28 fill !important; /* top | right | bottom | left | middle */

        height: 58px;
      }

      &.underline {
        font-family: PPPangramSansRounded-Medium;
        text-decoration: underline;
        opacity: 0.8;
        height: 36px;
      }

      width: 100%;
      height: 56px;

      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 6px;

      font-family: PPPangramSansRounded-Bold;
      font-size: 16px;

      .gameover-button-icon {
        height: 16px;
        margin-top: -2px;
        img {
          height: 100%;
        }
      }
    }
  }
}

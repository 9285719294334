.modal-drawer.drawerTradingCreationSuccess {
  .modal-drawer-box {
    border: 1px solid #09d0a8;
    border-bottom: unset;
    padding-top: 24px;
  }
}

.modal-drawer.drawerTradingTransactionSuccess {
  .modal-drawer-box {
    border: 1px solid #09d0a8;
    border-bottom: unset;
    padding-top: 24px;
  }
}

.modal-drawer.drawerTradingTransactionError {
  .modal-drawer-box {
    border: 1px solid #d00948;
    border-bottom: unset;
    padding-top: 24px;
  }
}

// ------------------------------

.drawer-trading-transaction-header {
  padding-bottom: 32px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;

  .drawer-trading-transaction-icon {
    width: 48px;
    height: 48px;
  }

  .drawer-trading-transaction-title {
    font-family: PPPangramSansRounded-Bold;
    font-size: 20px;
    margin-top: 6px;

    &.yellow {
      font-size: 32px;
      background: linear-gradient(305.83deg, #ffee10 27.33%, #ffb900 86.11%);
      -webkit-text-fill-color: transparent;
      background-clip: text;
      margin-bottom: -8px;
    }
  }

  .drawer-trading-transaction-subtitle {
    font-family: PPPangramSansRounded-Medium;
    font-size: 14px;
    line-height: 22px;
    color: #777;
  }

  .drawer-trading-transaction-reward {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 8px;

    font-family: PPPangramSansRounded-SemiBold;
    font-size: 14px;

    .icon {
      // background-color: #222;
      width: 20px;
      height: 20px;
      border-radius: 0px; // 6px;
    }
    .value {
      color: white;
    }
    .currency {
      color: #f5c005;
    }
  }
}

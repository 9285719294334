.modal-drawer.powerup-gift-expired {
  .drawer-layout {
    .drawer-header {
      padding-bottom: 8px;

      // ===============================
      // image

      // .drawer-header-icon {
      //   img {
      //     width: 80px;
      //     height: 80px;
      //   }
      // }

      // ===============================
      // title and subtitle

      .drawer-header-title {
        font-family: PPPangramSansRounded-SemiBold;
        font-size: 32px;
        margin-top: -36px;
        padding-bottom: 8px;
      }

      .drawer-header-subtitle {
        color: #999;
        padding-bottom: 24px;
      }

      .drawer-header-morecards {
        font-family: PPPangramSansRounded-Medium;
        font-size: 18px;
        line-height: 28px;
        color: #999;

        padding: 0 12px;
        padding-bottom: 24px;
      }

      // ===============================
      // earn

      .puc-earn {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        padding-top: 16px;
        padding-bottom: 8px;

        .puc-earn-title {
          margin-right: 8px;

          font-family: PPPangramSansRounded-Medium;
          font-size: 18px;
          color: #999;
        }

        .puc-earn-coins {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;

          margin-top: 8px;
          margin-bottom: -8px;

          font-family: PPPangramSansRounded-Bold;
          font-size: 18px;
          color: #f5c005;

          img {
            margin-right: 6px;
            width: 15px;
          }
        }
      }

      // ===============================
    }
  }
}

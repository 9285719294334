.modal-drawer .powerup-promo {
  .drawer-layout {
    .drawer-header {
      padding-bottom: 8px;

      // ===============================
      // image

      .drawer-header-icon {
        img {
          width: 30svh;
          height: 30svh;
          max-height: 30svh;
        }
      }

      // ===============================
      // title and subtitle

      .drawer-header-title {
        font-family: PPPangramSansRounded-SemiBold;
        font-size: 4.35svh;
        padding-top: 0;
        padding-bottom: 5svh;
      }

      .drawer-header-subtitle {
        font-size: 4svh;
        line-height: 3svh;
        padding: 2svh;
        color: #999;
      }

      // ===============================
      // earn

      .puc-earn {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .puc-earn-title {
          margin: 1svh;
          font-family: PPPangramSansRounded-Medium;
          font-size: 2.3svh;
          color: #999;
        }

        .puc-earn-coins {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;

          font-family: PPPangramSansRounded-Bold;
          font-size: 2.5svh;
          color: #f5c005;
        }
      }

      // ===============================
    }
  }
}

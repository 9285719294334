// ============================================
// Drawer Friends Details

.drawer-layout.friends-details {
  .friends-details-header {
    .friends-details-icon {
      img {
        width: 8svh;
        height: 8svh;
      }
    }

    .friends-details-title {
      font-family: PPPangramSansRounded-SemiBold;
      font-size: 3.8svh;
      padding-top: 8px;
    }

    .friends-details {
      font-family: PPPangramSansRounded-Medium;
      font-size: 2.2svh;
      padding-top: 1svh;
      color: #999;
    }

    .friends-details-title-lrc {
      font-family: PPPangramSansRounded-SemiBold;
      font-size: 1.8svh;
      padding-top: 1svh;
    }
  }

  .friends-bonus-list-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 1.5svh;

    padding-top: 2svh;
    padding-bottom: 0;

    .friends-bonus-list-header-label {
      font-family: PPPangramSansRounded-SemiBold;
      font-size: 1.8svh;
      text-align: right;
      color: #999;

      &.levelup {
        width: 150px;
        text-align: left;
        padding-left: 4px;
      }

      &.regular {
        width: 100px;
      }

      &.premium {
        width: 100px;
      }
    }
  }

  .friends-bonus-list-container {
    width: 100%;
    max-height: calc(73svh - 180px);
    overflow-y: scroll;
    margin-bottom: 1svh;

    .friends-bonus-list {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: start;
      justify-content: start;
      gap: 2svh;

      padding-bottom: 24px;

      .friends-bonus-item {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        gap: 1svh;

        .league-icon {
          img {
            width: 4svh;
            height: 4svh;
          }
        }
        .league-rank {
          font-family: PPPangramSansRounded-SemiBold;
          font-size: 2.1svh;
          text-align: left;
          width: 50%;
          width: 94px;
        }

        .referrer-level {
          font-family: PPPangramSansRounded-SemiBold;
          font-size: 1.8svh;
          text-align: left;
          //width: 50%;
          width: 94px;
        }

        .bonus-yellow {
          font-family: PPPangramSansRounded-SemiBold;
          font-size: 2.1svh;
          text-align: right;
          color: #f5c005;

          width: 100px;
        }
      }
    }
  }
}

.modal-drawer.auth-error {
  .modal-drawer-box {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: fit-content;
    background-color: black;
    padding: 24px;
    padding-top: 48px;

    border: 1px solid #444;
    border-bottom: unset;
    border-radius: 32px 32px 0 0;

    .modal-drawer-button-close {
      display: none;
    }
  }

  .drawer-layout {
    .drawer-header {
      padding-bottom: 8px;

      .drawer-header-title {
        font-family: PPPangramSansRounded-SemiBold;
        font-size: 32px;
        margin-top: -36px;
        padding-bottom: 8px;
      }

      .drawer-header-subtitle {
        color: #999;
        padding-bottom: 24px;
      }
    }
  }
}

// .page.earn {
//   background-color: red;
// }

.shop-header {
  padding-top: 16px;
}

.shop-subtitle {
  font-family: PPPangramSansRounded-Bold;
  font-size: 18px;
  padding-top: 28px;
  padding-bottom: 12px;
}

.shop-daily-boosts-container {
  width: 100%;
  .shop-daily-boosts {
    width: 100%;

    .daily-items {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 12px;

      .daily-item {
        pointer-events: auto;
        background-color: #222;
        border-radius: 12px;
        padding: 8px;
        width: 50%;
        height: 82px;

        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 12px;

        cursor: pointer;

        &.highlight {
          border: 1px solid #ffce06;
        }

        &.disabled {
          opacity: 0.6;
          cursor: initial;
          pointer-events: none;
        }

        .daily-icon {
          width: 36px;
          height: 36px;
          img {
            width: 36px;
            height: 36px;
          }
        }

        .daily-info {
          display: flex;
          flex-direction: column;
          align-items: start;
          justify-content: center;
          gap: 4px;

          .daily-info-title {
            font-family: PPPangramSansRounded-Bold;
            font-size: 16px;
            text-align: left;
            color: #ffb900;
          }
          .daily-info-subtitle {
            font-family: PPPangramSansRounded-Medium;
            font-size: 16px;
            text-align: left;
          }
        }
      }
    }
  }
}

.shop-list-container {
  width: 100%;
  height: fit-content;

  .shop-list {
    width: 100%;
    height: fit-content;

    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
  }
}

// ========================================================================================
// Booster Card

.booster-card {
  background-color: #222;
  border-radius: 12px;
  padding: 16px;
  width: 100%;
  height: 82px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 16px;

  cursor: pointer;

  &.disabled {
    opacity: 0.6;
    cursor: initial;
  }

  .booster-card-left {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
    gap: 16px;

    .booster-card-icon {
      width: 36px;
      height: 36px;
      img {
        width: 36px;
        height: 36px;
      }
    }

    .booster-card-info {
      width: 80%;

      display: flex;
      flex-direction: column;
      align-items: start;
      justify-content: center;
      gap: 4px;

      .booster-card-info-title {
        font-family: PPPangramSansRounded-Bold;
        font-size: 16px;
        text-align: left;
        color: #ffb900; // todo: change to gradient
      }

      .booster-card-info-points {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: start;
        gap: 6px;

        .booster-card-info-points-icon {
          width: 20px;
          height: 20px;
          img {
            width: 20px;
            height: 20px;
          }
        }

        .booster-card-info-points-value-ctn {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: start;
          gap: 1px;

          .booster-card-info-points-value {
            font-family: PPPangramSansRounded-Medium;
            font-size: 16px;
            text-align: left;
            padding-top: 3px;
          }

          li {
            margin-left: 8px;
          }

          .level {
            font-family: PPPangramSansRounded-SemiBold;
            font-size: 14px;
            color: rgb(255 255 255 / 87%);
            margin-top: 4px;
          }
        }

        .booster-card-info-subtitle {
          font-family: PPPangramSansRounded-Medium;
          font-size: 16px;
          text-align: left;
        }
      }
    }
  }

  // .booster-card-arrow {
  // }
}

.confetti-effect {
  pointer-events: none;
  z-index: 99999999999;

  position: fixed;
  top: 0;
  left: 0;

  width: 100%;
  max-width: 100%;
  height: 100%;
}

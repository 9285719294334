.rank {
  display: flex;
  width: 30px;
  height: 50px;
  align-items: center;
  justify-content: start;
  img {
    width: 30px;
    height: 30px;
  }
  .rank-label {
    width: 30px;
    white-space: nowrap;
  }
}

// ===================================================================
// Clicker Score (points and league labels)

.scoreboard {
  z-index: 1;
  color: white;
  text-align: center;
}

.timer {
  z-index: 1;
  font-family: PPPangramSansRounded-Bold;
  font-size: 16px;
  color: #f5c005; // linear-gradient(305.83deg, #ffee10 27.33%, #ffb900 86.11%);

  // #time {
  //   font-family: PPPangramSansRounded-Bold;
  //   margin-bottom: 1vh; // 12px;
  //   padding-left: 0.5vh;
  // }
}

.points {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 12px;

  .points-icon {
    width: 5.5vh; // 48px;
    height: 5.5vh; // 48px;
  }

  .points-label {
    font-family: PPPangramSansRounded-Semibold;
    font-size: 5.5vh; // 52px;
    padding-top: 5px;
  }
}

.league {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 4px;
  padding-bottom: 8px;
  margin-top: -4px;

  .league-icon {
    width: 3vh; // 32px;
    height: 3vh; // 32px;

    img {
      width: 3vh; // 24px;
      height: 3vh; // 32px;
    }
  }

  .league-label {
    font-size: 2.2vh; // 18px;
    font-family: PPPangramSansRounded-Medium;
    padding-top: 4px;
    text-transform: capitalize;
  }
}

.page.trading-create {
  background-color: black;

  .feed-item-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }
}

.trading-create-layout {
  position: relative;
  width: 100%;
  height: 100%; // fit-content;

  // in case we need button at the bottom in XR, but scrollable content on smaller phones
  // height: 100%;
  // min-height: 732px; // set this to the total height of the content

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;

  gap: 3svh; // 24px;

  .trading-create-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .trading-create-title {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      height: 124px;
      margin-top: 4svh;

      .label {
        font-size: 24px;
        line-height: 32px;
        padding-top: 32px;
      }
    }

    // user image - camera

    .trading-create-image {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      width: calc(19svh + 10px);
      height: 32svh;

      // width: 140px;
      // height: 180px;

      margin-top: 0svh;

      background-color: #222;
      border-radius: 16px;

      img {
        width: unset;
        height: unset;
        object-fit: cover;
      }

      .container {
        .img-container {
          .img-box {
            .btn-add {
              .image-container {
                display: flex;
                align-items: center;
                justify-content: center;
                overflow: hidden;

                width: calc(19svh + 10px - 10px);
                height: calc(32svh - 10px);

                border-radius: 12px;

                img {
                  width: unset;
                  height: unset;
                }
              }
            }
          }
        }
      }
    }
  }

  form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    gap: 16px;
  }

  .button-continue {
    height: 56px;
  }
}

// ============================================
//  daily menu with 3 buttons

.daily-menu {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  gap: 2svw;
  width: 100%;
  margin: 8px 0;
  margin-bottom: 4px;
  z-index: 2;

  .daily-btn {
    border-radius: 8px;
    padding: 3px;
    font-size: 3.25vmin;
    width: 33%;
    position: relative;

    border-image-source: url('../../../../assets/buttons/daily/daily_border.svg');
    border-image-width: 64px;
    border-image-slice: 32 32 32 32 fill; /* top | right | bottom | left | middle */

    &.db-active {
      // background: orange;
      border-image-source: url('../../../../assets/buttons/daily/daily_border_active.svg');

      .db-title {
        color: rgb(255, 210, 0);
      }

      .db-time {
        // color: rgb(255, 210, 0);;
        opacity: 1;
      }
    }

    &.complete {
      pointer-events: none !important;

      border-image-source: url('../../../../assets/buttons/daily/daily_border_completed.svg');
    }

    .db-img {
      padding-top: 2px;
      img {
        height: 6vmin;
        max-height: 24px;
      }
    }

    .db-title {
      margin-top: 1px;
    }

    .db-time {
      margin-top: 1px;
      opacity: 0.6;
    }

    .db-complete {
      position: absolute;
      top: -3px;
      right: -2px;
      img {
        width: 13px;
      }
    }
  }
}

// ============================================
//  daily code input field

.daily-code {
  z-index: 2;
  display: flex;
  align-items: center;
  width: 100%;
  height: 36px;
  background: #fcfcfc1c;

  margin-bottom: 10px;
  margin-top: 0;

  padding: 8px;
  box-sizing: border-box;

  border-radius: 8px;
  font-size: 12px;

  visibility: visible;
  transition: all 0.35s ease;
  transition-delay: 0.05s;

  &.removed {
    display: block !important;
    visibility: hidden;
    height: 0px;
    padding: 0;
    margin-bottom: 0;
  }

  .dc-title {
    text-align: left;
  }

  .dc-input {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    color: white;
  }

  .dc-prize {
    display: flex;
    align-items: center;
    justify-content: center;
    color: yellow;
    font-size: 13px;

    img {
      width: 20px;
      margin-right: 4px;
    }
  }
}

.trading-create-image {
  overflow: hidden;

  // border: 0.5px solid rgba(255, 0, 0, 1);
  // border: 0.5px solid #999;

  .container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .img-box {
    img {
      width: 100%;
    }
  }

  .warning {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
    gap: 4px;

    font-family: PPPangramSansRounded-Medium;
    font-size: 12px;
    color: #777;
    text-align: left;

    margin-top: 4px;

    .title {
      color: #777;
    }
    .required {
      font-size: 14px;
      color: red;
    }
  }

  .error {
    margin-top: 24px;
    p {
      color: red;
    }
  }

  // .result {
  //   display: flex;
  //   flex-direction: column;
  //   align-items: center;
  //   justify-content: center;

  //   a {
  //     margin-bottom: 8px;
  //   }
  // }

  // .link {
  //   margin-bottom: 24px;
  //   button {
  //     margin-top: 12px;
  //   }
  // }
}

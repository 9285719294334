.user-balance {
  .user-balance-title {
    font-family: PPPangramSansRounded-Medium;
    font-size: 20px;
    color: #777;
  }

  // =============================================
  // points

  .user-balance-points {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 12px;

    width: 100%;

    .user-balance-points-icon {
      width: 40px;
      height: 40px;
    }
    .user-balance-points-label {
      font-family: PPPangramSansRounded-Semibold;
      font-size: 42px;
      padding-top: 6px;
    }
  }

  // =============================================
  // earn per hour

  .user-balance-per-hour-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 14px;

    .user-balance-earn-per-hour {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 4px;

      padding-top: 4px;
      box-sizing: border-box;

      font-family: PPPangramSansRounded-Semibold;
      font-size: 14px;

      img {
        width: 16px;
        height: 16px;
      }

      .yellow {
        color: #f5c005;
      }
    }

    .s2-migration-warning {
      background: linear-gradient(90deg, #7838f9 0%, #32038f 100%);
      border-radius: 30px;
      padding: 8px 12px;
      width: fit-content;

      background-color: orange;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 8px;

      .loading {
        width: 16px;
        height: 16px;
        img {
          width: 100%;
        }
      }

      .label {
        font-family: PPPangramSansRounded-Bold;
        font-size: 12px;
        padding-top: 1px;
      }
    }
  }
}

@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.rotating {
  -webkit-animation: rotating 2s linear infinite;
  -moz-animation: rotating 2s linear infinite;
  -ms-animation: rotating 2s linear infinite;
  -o-animation: rotating 2s linear infinite;
  animation: rotating 2s linear infinite;
}

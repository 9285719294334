.follow-entry-container {
  padding: 8px;

  .follow-entry {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .follow-picture {
      img {
        height: 40px;
        border-radius: 100%;
      }
    }

    .follow-details {
      .follow-name {
        font-size: 16px;
        font-weight: bold;
        text-align: left;
      }

      .follow-stats {
        font-size: 12px;
        text-align: left;
      }
    }

    .follow-btns {
      div {
        width: 100px;
        height: 30px;
        font-size: 14px;
      }
    }
  }
}

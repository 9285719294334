$slider-color: #f5c005;
$slider-color-bg: #444;
$slider-color-glow: #f5c00544;

.dotted-slider {
  width: 100%;
  padding: 2px 0;
  position: relative;

  &-labels {
    position: relative;
    height: 20px;
    margin-bottom: 5px;
  }

  &-label {
    position: absolute;
    font-size: 14px;
    color: #888;
    transform: translateX(-50%);
    white-space: nowrap;

    &.left {
      transform: translateX(0);
    }

    &.right {
      transform: translateX(-100%);
    }
  }

  &-track {
    position: relative;
    height: 16px;
    background-color: #2a2a2a;
    border-radius: 8px;
    cursor: pointer;
  }

  &-handler {
    z-index: 1;
    position: absolute;
    top: -2px;
    left: 0px;
    width: 20px;
    height: 20px;
    border-radius: 90px;
    background-color: $slider-color;

    box-shadow: 0 0 0 6px $slider-color-glow;
    transform: translate(-50%, -50%);
  }

  &-fill {
    position: absolute;
    height: 100%;
    background: linear-gradient(90deg, #f5a623 0%, #f5c005 100%);
    border-radius: 8px;

    // note: Damon does not like it that it doesnt snap to the finger
    // transition: width 0.1s ease;
  }

  &-dot {
    position: absolute;
    width: 16px;
    height: 16px;
    background-color: transparent;
    border-radius: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    transition: all 0.3s ease;
    pointer-events: none;

    &-inner {
      position: absolute;
      width: 6px;
      height: 6px;
      background-color: #888;
      border-radius: 50%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    &.active {
      width: 20px;
      height: 20px;
      border: 2px solid #f5c005;
      background-color: #f5c005;
    }

    &.end-dot {
      .dotted-slider-dot-inner {
        display: none;
      }
    }
  }
}
